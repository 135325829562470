<template>

  <div class="aboutPage_wrapper">
    <div class="news_inner-container">
      <div class="articleOpen"
           data-aos="fade-right"
           data-aos-duration="1500"
           v-if="currentArticle.length !== 0">
        <div class="iconWrapper">
          <span class="mainTitle">Новини</span>
          <div class="closeContainer" @click="this.currentArticle.pop()">
            <CloseIcon class="closeIcon" size="50"/>
          </div>
        </div>
        <span class="aTitle">{{currentArticle[0].title}}</span>
        <div class="with-picture">
          <img class="aPicture" v-if="currentArticle[0].article_picture!==null && currentArticle[0].article_picture!==undefined" :src="getImgUrl(currentArticle[0].article_picture)"/>
          <p class="aText" v-html="currentArticle[0].fullText"></p>
        </div>
        <p class="aData">{{currentArticle[0].dateOfNew}}</p>
      </div>
      <div v-if="currentArticle.length === 0" class="buttons_Block">
        <!--        <div class="application-btn" style="color: #12b423">Новини</div>-->
        <!--        <div class="application-btn">Статті</div>-->
      </div>
      <span v-if="currentArticle.length === 0" class="title_news">Новини Сучасний Факторинг</span>
      <div v-if="news.length > 0 && currentArticle.length === 0" class="news_container">
        <div class="new_card"
             v-for="(item, index) in arrayCutter(news)"
             :key=index>

<!--          <img class="newPhoto" :src="`${item.photoURL}`" alt="newPhoto"/>-->
<!--          <PhotoNewPicture :title='item.title' :photo='item.photo'/>-->

          <div class="content_block">
            <span class="title_new" @click="openNewHandler(item)">{{ item.title }}</span>
            <p class="text_new">{{ item.shortText }}</p>
            <span class="data_new">{{ item.dateOfNew }}</span>
          </div>
        </div>

        <div class="buttons_Block" v-if="this.news.length > 3 && currentArticle.length === 0">
          <div class="application-btn" style="background-color: #12b423;color:white">Більше</div>
        </div>
      </div>
      <span v-if="currentArticle.length === 0 && news.length === 0"
            class="no_text-style">Поки що у вас немає новин</span>


      <!--      <span v-if="currentArticle.length === 0" class="title_news" style="max-width: 100px">Статті</span>-->
      <!--      <div v-if="articles.length > 0 && currentArticle.length === 0" class="articles_container">-->
      <!--        <div class="article"-->
      <!--             v-for="(item, index) in articles"-->
      <!--             :key="index">-->
      <!--          <div class="article-content_block">-->
      <!--            <span @click="openArticleHandler(item)" class="title_new">{{ item.title }}</span>-->
      <!--            <span  class="text_new">{{  textCutter(item.text) }}</span>-->
      <!--            <span class="data_new">{{ item.data }}</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <span  v-if="currentArticle.length === 0 && articles.length === 0" class="no_text-style">Поки що у вас немає статей</span>-->
    </div>

    <div class="good_inner-container">
      <ContactsBlock/>
    </div>
    <div class="good_inner-container">
      <FeedbackForm/>
    </div>

  </div>
</template>
<script>

import ContactsBlock from "@/components/common/contacts/ContactsBlock";
import FeedbackForm from "@/components/common/FeedbackForm/FeedbackForm";
import CloseIcon from "vue-material-design-icons/Plus.vue";
// import PhotoNewPicture from "@/views/PhotoNewPicture.vue";


export default {

  name: 'news_page',
  components: {
    ContactsBlock,
    FeedbackForm,
    CloseIcon,
    // PhotoNewPicture
  },
  mounted() {
    this.$emit('scrollTop');
    document.title = 'Сучасний факторинг|Новини ';
  },
  methods: {
    ScrollUpFast(){
      let t, s;
      s=document.body.scrollTop||window.pageYOffset;
      t=setInterval(function(){if(s>0)window.scroll(0,s-=5);else clearInterval(t)},5);
      return!1
    },
    arrayCutter(arr) {
      if (arr.length >= 5) {
        return arr.slice(1, arr.length);
      } else {
        return arr
      }
    },
    openNewHandler(item) {
      this.currentArticle.push(item);
      this.ScrollUpFast();
    },
    textCutter(text) {
      return text.split(" ").splice(0, 20).join(" ") + '...'
    },
    getImgUrl(pic) {
      if(pic!==null && pic!==undefined) {
        return require('../assets/' + pic)
      } else {
        return require('../assets/free-icon-map-7224158.png')
      }
    }
  },

  data() {
    return {
      currentArticle: [],
      news: [
        {
          photo: "free-icon-map-7224158.png",
          title: "Привітання з новим роком",
          shortText: "Дорогі наші клієнти, з найтеплішими побажаннями від ТОВ Сучасного факторингу ми вітаємо вас з Різдвом та Наступаючим Новим Роком 2024! 🎄🎉. Це особливий час, коли дива стаються, а серця наповнюються радістю.",
          fullText: "Дорогі наші клієнти,<br/>"+
              "З найтеплішими побажаннями від ТОВ Сучасного факторингу ми вітаємо вас з Різдвом та Наступаючим Новим Роком 2024! 🎄🎉<br/>"+
              "Це особливий час, коли дива стаються, а серця наповнюються радістю. Ми хочемо виразити нашу щирість і подяку за ваш вибір ТОВ Сучасного факторингу. Ваша довіра є для нас великою цінністю.<br/>" +
              "Нехай ці святкові дні принесуть вам тепло, усмішки та безмежну радість. Бажаємо вам фінансового комфорту, успіхів у всіх починаннях та здійснення всіх мрій у новому році! 🌟💖<br/>" +
              "Дякуємо, що обрали нас! З найкращими побажаннями,<br/>" +
              "Команда ТОВ Сучасного факторингу",
          author: "", dateOfNew: "27.12.2023",
          article_picture: "pictures/im06178_Here_is_the_illustrated_scene_in_the_style_of_2000s_car_b0922cd0-b2d8-4d86-9e37-f563a1b0c8db.png"
        },
        {
          photo: "free-icon-map-7224158.png",
          title: "Як оформити реструктуризацію та зменшити щомісячний платіж по кредиту?",
          shortText: "Шановні Клієнти! Попри те, що кредитні канікули закінчились і оплата за основною сумою кредиту знову є обов’язковою, ми продовжуємо Вас підтримувати та надаємо можливість оформити реструктуризацію.",
          fullText: "Якщо у Вас виникли складнощі щодо обслуговування кредиту, Ви можете звернутися до компанії для вирішення питання.\n" +
              "Доступність реструктуризації та її умови, залежить від поточного стану Вашого боргу та діючих умов Вашого кредитного договору. \n" +
              "Переваги реструктуризації:\n" +
              "<br><strong>1.</strong> Можливість отримати новий графік погашення кредиту, де щомісячний платіж може бути зменшено на 20%,від суми Вашого поточного щомісячного платежу за кредитним договором.\n" +
              "<br><strong>2.</strong> Якщо Ви скористаєтесь даними продуктами, реальна процентна ставка за Вашим кредитом внаслідок реструктуризації не збільшиться. \n" +
              "<br><br>Як оформити реструктуризацію?\n" +
              "<br><strong>1.</strong> Якщо Ви маєте діючий креди та бажаєте реструктуризувати його, відправте, будь ласка, запит на корпоративную почту info@sfactor.com.ua.\n" +
              "<br><strong>2.</strong> Компанія розгляне Ваше звернення та зателефонує Вам для повідомлення рішення.\n",
          author: "", dateOfNew: "20.02.2023"
      },
      {
          photo: "free-icon-map-7224158.png",
          title: "Погашення кредиту без сплати відсотків для учасників бойових дій.",
          shortText: "Шановні клієнти! Наша компанія прикладає всі зусилля, щоб підтримати учасників бойових дій. Наразі учасники бойових дій або їхні родичі мають можливість погасити тіло кредиту без сплати відсотків, якщо кредит був оформлений в 2022 році. \n",
          fullText: "Компанія анулює відсотки за кредитами, з моменту проходження служби, клієнтам  потрібно сплатити лише тіло кредиту.\n" +
              "Хто має право на закриття кредиту без сплати відсотків?\n" +
              "Клієнти, які є активними учасниками бойових дій або їхніми родичами. Це може бути чоловік/дружина, брат/сестра, батько/мати, син/дочка. \n" +
              "Як усе проходить?\n" +
              "Клієнт надає документ, який підтверджує проходження військової служби та надсилає його на погодження на пошту компанії info@sfactor.com.ua.\n" +
              "Компанія приймає рішення, щодо списання відсотків по кредиту.\n" +
              "Клієнт погашає тіло кредиту повністю.\n" +
              "Компанія проводить списання (срок списання 14 днів).",

          author: "", dateOfNew: "20.02.2023"
      }
    ],
      articles: [
        {
          title: 'Чому кредитна історія важлива?',
          text: 'Кожен позичальник повинен знати свою кредитну історію та ставитись до цього питання дуже дбайливо. Цей показник в подальшому буде впливати на те, чи буде виданий кредит. Але часто постає питання: «Що робити, якщо кредитна історія зіпсована?» Не хвилюйтесь. В цій статті ми допоможемо вирішити вашу проблему. Розберемося спочатку, які бувають кредитні історії:\n' +
              '<br/>1.Добра. Якщо позичальник своєчасно сплачував борги та дотримувався правил заборгованності.\n' +
              '<br/>2.Погана. Якщо позичальник має прострочення. Також, якщо загалом не виплачувався борг - людина потрапляє у чорний список фінансової компанії.\n' +
              '<br/>3.Чиста. Людина не користувалася послугою кредиту.\n' +
              'Важливо слідкувати за стабільністю кредитної історії. Вона може знизитись, якщо позичальник не платив борг кожен місяць. Але є ще фактори, коли людина може залишити «поганий» слід в кредитної історії:\n' +
              '<br/>1.Взяття боргу в ненадійній організації. Не в усіх компаніях є добра репутація.\n' +
              '<br/>2.Якщо ваші дані хтось використав у своїх цілях.\n' +
              '<br/>3.У кредитному договорі були якісь «прозорі» комісії, але ви їх не помітили.\n' +
              'Якщо ваша кредитна історія погана внаслідок одного з цих факторів, ви повинні\n' +
              'піти до правоохоронних органів чи до суду. Дані по кредитам, на жаль, видалити з кредитного бюро не можна. Але достатньо просто покращити свою кредитну історію такими простими методами:\n' +
              '<br/>1.Ви повинні якомога швидше погасити простроченний кредит. Це дуже важливо, бо кредитор має право звернутися до суду в тому разі, якщо є фіксовані заборгованності. \n' +
              '<br/>2.Варто вносити платіж своєчасно, якщо у вас є кредитна картка або ви брали кредит на побутову техніку. Ця інформація також фіксується і впливає на рішення банку.\n' +
              '<br/>3.Для уникнення поганої кредитної  історії, за фінансових проблем, варто звернутися до банку з проханням надати вам реструктуризацію боргу. Це допоможе зберегти чистоту кредитного рейтингу, а також дасть можливість усунути заборгованість, що утворилася.\n' +
              'Візьміть позику у «Сучасному Факторингу». Тут є найвигідніші пропозиції. Це допоможе вчасно закрити кредит та покращити кредитну історію.',
          data: '25.07.2022'
        },
        {
          title: 'Обираємо техніку правильно',
          text: 'Обираємо техніку правильно\n' +
              'Найголовніше правило у виборі техніки - «Краще один раз побачити», але це ще не все! Треба ретельно переглядати технічні характеристики та особливості експлуатації. Також, не буде зайвим переконатися в необхідності цих електронних пристроїв. Нижче пропонуємо вам наші лайфхаки:\n' +
              '<br/><strong>1.Дзвінок другу\n</strong><br/>' +
              'Найкращим другом у виборі техніки є менеджер чи консультант. Обирати такі речі самостійно дуже складно, але деякі речі потрібно знати кожному при виборі будь-якої техніки.\n' +
              '<br/><strong>2.Функції товару\n</strong><br/>' +
              'У багатьох випадках висока ціна розкрученого бренду обумовлюється лише переважно популярністю та великою кількістю додаткових функцій. При виборі техніки завжди треба замислитись про певні функції. Чи потрібні вони вам? Цікаво, що за статистикою більшість не користуються всім функціоналом новомодної техніки.\n' +
              '<br/><strong>3.Вітрина\n</strong><br/>' +
              'Дуже часто трапляється така ситуація, коли з вуст продавця лунає фраза: «На вітрині останній екземпляр, швидше купуйте!» Чи варто брати товар після цієї фрази? Це особиста справа. Але треба враховувати той факт, що товар, який виставляється на вітрині, частіше за все потребує сервісного обслуговування.\n' +
              '<br/><strong>4.Чи вигідні витрати електроенергії?\n</strong><br/>' +
              'При виборі побутового пристрою треба завжди враховувати витрати електроенергії. Особливо це важливо у будинках старого зразку. Перед придбанням треба проконсультуватися у грамотного електрика.\n' +
              '<br/><strong>5.Розмір важливий!\n</strong><br/>' +
              'Щоб правильно обрати товар, треба переконатися чи підходить він за розміром, буде то електропіч чи духовка. Часто трапляється таке, що не підходить 2-3 см. Будьте уважні!\n' +
              '<br/><strong>6.Що? Де? Як працює?\n</strong><br/>' +
              'Досить важливо при покупці знати якомога більше про товар. Не соромтесь задавати більше питань про роботу техніки та характеристики. Зібравши необхідну інформацію можна вирішити, яка модель вам точно підійде. \n' +
              '<br/>Сподіваємося, що вам були корисні наші лайфхаки!',
          data: '26.07.2022'
        },
        {
          title: 'Як зекономити на подарунках?',
          text: 'Завжди актуальна тема: «Подарунки на свята (Новий рік, 8 Березня, День закоханих тощо) та як не залишитись після них без грошей?»\n' +
              'Саме для цієї ситуації ми підготували 5 порад щодо економії грошей на подарунки:\n' +
              '<br/>1. Корисний подарунок.\n' +
              'Добре подумайте над тим, що дійсно необхідно людині. За статистикою, великий відсоток людей не користуються подарунками, які їм дарують. Тому обирайте практичні, цікаві та потрібні речі.\n' +
              '<br/>2. Зробіть замітки до свят заздалегідь.\n' +
              'Напишіть перелік товарів, які бажаєте придбати та робіть помітки, коли ваш улюблений магазин проводить розпродажі або інші акції, щоб придбати омріяний товар і не переплатити шалену суму за нього.\n' +
              '<br/>3. Слідкуйте за акціями та знижками.\n' +
              'Промоніторте та розплануйте строки своїх покупок, особливо дорогих товарів. Порівняйте ціни в декількох торгових точках, що б не купити в першому ліпшому місці. Адже в різних магазинах - різні ціни.  Так Ви зможете добряче заощадити, а подарунок буде той, який ви запланували.\n' +
              '<br/>4. Скарбничка.\n' +
              'Щоб добре заощадити гроші на подарунках - відкладайте їх. Так ви зможете стратегічно розподілити бюджет та зекономити. Ваш гаманець буде вам вдячний.\n' +
              '<br/>5.«Сучасний Факторинг»\n' +
              'Отримайте кредит у «Сучасному Факторингу». Так вам точно вистачить на подарунки, де підберуть вигідні умови під фіксований відсоток саме для вас. \n' +
              '<br/>Псс.. До речі, багато населення думає, що краще подарувати багато різних подарунків замість одного великого. Але це не так, якщо подарувати один якісний подарунок, є великий відсоток того, що він точно сподобається та піде на користь близькій людині.\n' +
              '<br/>Сподіваємося вам сподобались наші поради. Веселих свят!',
          data: '29.07.2022'
        },
      ],
    }
  }
}
</script>
<style lang="scss" scoped>
.aboutPage_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .news_inner-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 1400px;
    position: relative;

    .no_text-style {
      color: #000000;
      font-size: 18px;
      font-family: "Montserrat", Arial, sans-serif;
      line-height: 1.5;
      font-weight: 600;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      margin-left: 50px;
    }

    .buttons_Block {
      display: flex;
      margin-top: 30px;
      margin-bottom: 40px;
      justify-content: flex-start;
    }

    .title_news {
      color: #0388d1;
      font-size: 45px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.55;
      font-weight: 700;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      margin-bottom: 40px;

    }

    .articles_container {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      margin-left: 50px;

      .article {
        display: flex;
        margin-bottom: 20px;

        .article-content_block {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          .title_new {
            font-size: 18px;
            font-weight: 600;
            font-family: 'Montserrat', Arial, sans-serif;
            line-height: 1.35;
            cursor: pointer;
          }
          .title_new:hover{
            color: #0388d1;

          }

          .text_new {
            font-family: 'Montserrat', Arial, sans-serif;
            font-weight: 300;
            font-size: 16px;
            margin-top: 8px;
            color: rgba(0, 0, 0, .9);
          }

          .data_new {
            display: inline-block;
            vertical-align: middle;
            font-size: 10px;
            letter-spacing: 1px;
            color: rgba(0, 0, 0, .5);
            font-family: 'Montserrat', Arial, sans-serif;
            font-weight: 600;
            margin-top: 10px;
          }
        }
      }
    }

    .news_container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .new_card {
        display: flex;
        margin-bottom: 20px;
        width: 100%;

        .newPhoto {
          width: 300px;
          align-self: center;
          margin-right: 20px;
        }

        .content_block {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          max-width: 100%;
          justify-content: space-between;

          .title_new {
            font-size: 18px;
            font-weight: 600;
            font-family: 'Montserrat', Arial, sans-serif;
            line-height: 1.35;
            cursor: pointer;
          }
          .title_new:hover{
            color:blue;
          }

          .text_new {;
            font-family: 'Montserrat', Arial, sans-serif;
            font-weight: 300;
            font-size: 16px;
            margin-top: 8px;
            color: rgba(0, 0, 0, .9);
          }

          .data_new {
            display: inline-block;
            vertical-align: middle;
            font-size: 10px;
            letter-spacing: 1px;
            color: rgba(0, 0, 0, .5);
            font-family: 'Montserrat', Arial, sans-serif;
            font-weight: 600;
            margin-top: 10px;
            align-self: flex-end;
          }
        }
      }
    }
  }

}

.application-btn {
  height: 40px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  background-color: transparent;
  transition: all .5s;
  color: #3b3b3b;
  border: 1px solid #12b423;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.good_inner-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.articleOpen {
  background-color: white;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;

  .with-picture {
    display: flex;
  }

  .aTitle {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: 600;
    color: #000;
    font-size: 32px;
    text-align: center;
    align-self: center;
    margin-top: 30px;
  }

  .aText {
    width: 100%;
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 18px;
    margin-top: 20px;
    text-align: justify;

    line-height: 1.55;
    align-self: auto;
    margin-left: 20px;
  }
  .aData {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: 600;
    color: #000;
    font-size: 10px;
    letter-spacing: 1.5px;
    align-self: flex-end;
  }
.openNewPicture{
  max-width: 50%;
  align-self: center;
  margin-top: 20px;
}
  .iconWrapper {
    display: flex;
    justify-content: flex-end;
    border-bottom: 2px solid grey;

    .mainTitle {
      align-self: center;
      margin: 0 auto;
      display: inline-block;
      opacity: .8;
      font-weight: 600;
      line-height: 1.3;
      font-family: 'Montserrat', Arial, sans-serif;
    }

    .closeContainer {
      display: inline-block;
      transform: rotate(45deg);
      margin-top: 20px;

      .closeIcon {
        color: #12b423;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 850px) {
  .articleOpen {
    .with-picture {
      display: flex;
      flex-direction: column;
      align-content: center;
      text-align: center;
      align-self: center;
      .aPicture {
        text-align: center;
        align-self: center;
        max-width: 400px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .articleOpen {
    .aText {
      max-width: 70%;
    }
  }
}

@media (max-width: 800px) {
  .articleOpen {
    .aText {
      max-width: 90%;
    }
  }
}

@media (max-width: 650px) {
  .aboutPage_wrapper {
    .news_inner-container {
      .title_news{
        font-size: 18px;
      }
      .articleOpen{
        .aTitle{
          font-size: 24px;
        }
        .aData{
          margin-right: 10px;
        }
      }
      .news_container {
        .new_card {
          flex-direction: column;
          justify-content:center;
          .newPhoto {
            align-self: center;
            width: 100%;
          }
          .content_block {
            max-width: 100%;
            align-self: center;
          }
        }
      }
    }
  }

}


</style>
