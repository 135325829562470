<template>
<div class="app">
  <header-main/>
  <div class="header">
    <div class="progress-container">
      <div class="progress-bar" id="myBar"></div>
    </div>
  </div>
  <BannerHeader v-if="showBanner"/>
  <router-view @scrollTop="ScrollUpFast"/>
  <a class="toTopBtn" @click="ScrollUp" :class="{ active: isActive }">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve"> <path style="fill:#12b422;" d="M43.006,47.529H4.964c-2.635,0-4.791-2.156-4.791-4.791V4.697c0-2.635,2.156-4.791,4.791-4.791h38.042 c2.635,0,4.791,2.156,4.791,4.791v38.042C47.797,45.373,45.641,47.529,43.006,47.529z M25.503,16.881l6.994,7.049 c0.583,0.588,1.532,0.592,2.121,0.008c0.588-0.583,0.592-1.533,0.008-2.122l-9.562-9.637c-0.281-0.283-0.664-0.443-1.063-0.443 c0,0,0,0-0.001,0c-0.399,0-0.782,0.159-1.063,0.442l-9.591,9.637c-0.584,0.587-0.583,1.537,0.005,2.121 c0.292,0.292,0.675,0.437,1.058,0.437c0.385,0,0.77-0.147,1.063-0.442L22.5,16.87v19.163c0,0.828,0.671,1.5,1.5,1.5 s1.5-0.672,1.5-1.5L25.503,16.881z"></path> </svg></a>
<footer-app/>
</div>

</template>

<script>

import HeaderMain from "@/components/common/header/MainHeader";
import FooterApp from "./components/common/footer/footer"
import BannerHeader from "@/components/common/header/BannerHeader.vue";

export default {
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  name: 'App',
  data(){
    return{
      isActive: true,
      showBanner: false
    }
  },
  components: {
    BannerHeader,
    HeaderMain,
    FooterApp
     },
  methods: {
    handleScroll() {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      document.getElementById("myBar").style.width = scrolled + "%";
      if(window.pageYOffset > 600) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },
    clickHandler() {

      window.scrollTo(0,0);return!1;
    },
    ScrollUp(){
      let t, s;
      s=document.body.scrollTop||window.pageYOffset;
      t=setInterval(function(){if(s>0)window.scroll(0,s-=5);else clearInterval(t)},5);
      return!1
    },
    ScrollUpFast(){
      let t, s;
      s=document.body.scrollTop||window.pageYOffset;
      t=setInterval(function(){if(s>0)window.scroll(0,s=s-1000);else clearInterval(t)},1);
      return!1
    },
  },
};


</script>
<style >
html,body{
  margin: 0;
  padding: 0;

}
.app{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
a {
  text-decoration: none;
  color:#0294d7;
  cursor: pointer;
}
.header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: #f1f1f1;
}
.progress-container {
  width: 100%;
  height: 8px;
  background: #ebebeb;
}

/* The progress bar (scroll indicator) */
.progress-bar {
  height: 8px;
  background: #4caf50;
  width: 0%;
}
.toTopBtn{
  display:inline-block;
  position:fixed;
  bottom:30px;
  left:30px;
  padding:5px 10px;font-size:16px;
  border-radius:15px;
  z-index:100;
}
.active{
  display: none;
}
@media (max-width: 630px) {
  .toTopBtn{
    display: none;
  }
}
</style>
