<template>
  <div class="calc_wrapper" @click="handlerClick">

    <div class="block_name"
         data-aos="fade-up"
         data-aos-duration="1500">
          <span class="text_style text_style_bold">
            Кредитні продукти
          </span>
      <div class="block_name-btn">
        <div class="application-btn"
             @click="typeOfCreditSelector('good')"
             :class="{ btn_green: typeOfLoan === 'good'}"
        >
          Кредит на товар
        </div>
        <div class="application-btn"
             @click="typeOfCreditSelector('cash')"
             :class="{ btn_green: typeOfLoan === 'cash'}"
        >
          Кошти в кредит
        </div>
<!--        <div class="application-btn"-->
<!--             @click="typeOfCreditSelector('untilSalary')"-->
<!--             :class="{ btn_green: typeOfLoan === 'untilSalary'}"-->
<!--        >-->
<!--          Гроші до ЗП-->
<!--        </div>-->
        <div class="messageWrap" data-aos="fade-up"
             data-aos-duration="1500"
        v-if=false
        >
          <img class="attention_pic"
               data-original="https://static.tildacdn.com/tild3235-6362-4439-b331-393533373330/platon-logo.png"
               src="../assets/klipartz.com.png">
          <div class="infoMessage">
            Шановні клієнти. Наразі оформлення готівкових кредитів тимчасово неможливе.
            Ви можете скористатися продуктом "Кредит на товар" в магазинах наших партнерів.
          </div>
        </div>
      </div>
      <div class="support_wrapper">
        <div class="support_block">
          <span class="support-title">Потрібна допомога?</span>
          <div class="photo_wrapper">
            <img src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1644939969372949.jpg">
          </div>
          <span class="support-title">Карина</span>
          <span class="text_style" style="text-align: center">твій персональний менеджер</span>
<!--          <div class="application-btn btn_green"-->
<!--               @click="typeOfCreditSelector('cash')"-->
<!--               :class="{ btn_green: typeOfLoan === 'cash'}"-->
<!--          >-->
<!--            онлайн чат-->
<!--          </div>-->
          <span class="support-title">+38 (044) 593-66-33</span>
        </div>
      </div>
    </div>
    <div class="calk_block-container">
      <div class="calc_block">
        <span v-if=false class="infoMessage"
              data-aos="fade-up"
              data-aos-duration="1000"
        >
          Нажаль цей продукт зараз не доступний.
        </span>
      <div class="calc_block-inner">
        <span class="text_style">Сума кредиту: <span style="font-weight: bold">{{ money_range }}</span></span>
        <span class="text_style">₴</span>
        <input v-model="money_range" name="N1" class="t-range "
               type="range" v-bind:min="valueMin()" v-bind:max="valueMax()"
               @change=this.recalculate(this.typeOfLoan);
               step="1000"/>
        <div style="display:flex;justify-content: space-between; max-width: 90%;width: 100%;margin-bottom:30px">
          <span class="text_style">{{ valueMin() }}</span>
          <span class="text_style">{{ valueMax() }}</span>
        </div>
        <span v-if="typeOfLoan === 'untilSalary'" class="text_style"
              style="margin-bottom: 30px">Кількість місяців: <span style="font-weight: bold">1</span></span>
        <span v-else class="text_style" style="margin-bottom: 30px">Кількість місяців: <span style="font-weight: bold">{{ mounth_range }}</span></span>
        <input v-if="typeOfLoan !== 'untilSalary'" v-model="mounth_range" name="N1" class="t-range "
               type="range"
               v-bind:min = mounthRangeMin()
               v-bind:max = mounthRangeMax()

               @change=this.recalculate(this.typeOfLoan);
               v-bind:step= stepIdent()
        />

        <div style="display:flex;justify-content: space-between; max-width: 90%;width: 100%;margin-bottom:30px">
          <span v-if="typeOfLoan !== 'untilSalary'" class="text_style">{{ mounthRangeMin() }}</span>
          <span v-if="typeOfLoan !== 'untilSalary'" class="text_style">{{ mounthRangeMax() }}</span>
        </div>
        <span v-if="typeOfLoan === 'cash'" class="text_style">
          Щомісячний платіж, від
        </span>
        <span v-else class="text_style">
          Щомісячний платіж
        </span>
        <span class="text_style"
              style="font-weight: 600;font-size: 18px">₴{{ mouthlyPayment }}/міс.</span>

        <div class="dropDownMenu"
             v-if="dropDownToggle">
          <span class="navLink"  @click="linkRedirector('https://slvr.one/!/higDR')">

              Істотні характеристики продукту (вище однієї мін. зароб. плати)

          </span>
          <span class="navLink"  @click="linkRedirector('https://slvr.one/!/7Y9xr')">
            Істотні характеристики продукту (менше однієї мін. зароб. плати)
          </span>
        </div>

<!--        @click="linkRedirector(typeOfLoan === 'cash'? 'https://slvr.one/!/OJ1Xi' : 'https://slvr.one/!/X35q7')"-->
      <div class="links_wrapper">
        <div class="link_calk"
             @mouseover="this.mouseoverHandler"
             @mouseleave="this.mouseLeaveHandler"
             @click="typeOfLoan === 'cash'? linkRedirector('https://slvr.one/!/LZna2') : aboutUsHandler()"
        >
          <img class="link_image"
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8---100.webp"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8---100.webp">
          <span class="link_title">Істотні характеристики</span>
        </div>
        <div class="link_calk"
             @click="linkRedirector(typeOfLoan === 'cash'? 'https://slvr.one/!/aBbQm' : 'https://slvr.one/!/azd2m')"
        >
          <img class="link_image"
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8---100.webp"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8---100.webp">
          <span class="link_title">Приклади розрахунків</span>
        </div>
        <div class="link_calk"
             @click="linkRedirector(typeOfLoan === 'cash'? 'https://slvr.one/!/Mydk1' : 'https://slvr.one/!/d03Si')">
          <img class="link_image"
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8--100.png"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8--100.png">
          <span class="link_title">Договір та правила</span>
        </div>
        <div class="link_calk"
             @click="linkRedirector('https://slvr.one/!/pbVxm')">
          <img class="link_image"
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8-----100.webp"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8-----100.webp">
          <span class="link_title">Інформування клієнтів</span>
        </div>
        <div class="link_calk" v-if="typeOfLoan==='good'"
             @click="linkRedirector('https://slvr.one/!/YSptl')">
          <img class="link_image"
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8-----100.webp"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8-----100.webp">
          <span class="link_title">Паспорт споживчого кредиту</span>
        </div>
        <div class="link_calk" v-else
             @click="linkRedirector('https://slvr.one/!/ZZehZ')">
          <img class="link_image"
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8-----100.webp"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8-----100.webp">
          <span class="link_title">Паспорт споживчого кредиту</span>
        </div>
        <div class="link_calk"
             @click="linkRedirector(typeOfLoan === 'cash'? 'https://slvr.one/!/niU5Y': 'https://slvr.one/!/Hv8VQ')">
          <img class="link_image"
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8-----100.webp"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8-----100.webp">
          <span class="link_title">Публічна пропозиція</span>
        </div>
      </div>
      </div>
</div>
    </div>
    <div class="support_wrapper-bottom">
      <div class="support_block">
        <span class="support-title">Потрібна допомога?</span>
        <div class="photo_wrapper">
          <img class="money_img"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1644939969372949.jpg"/>
        </div>
        <span class="support-title">Карина</span>
        <span class="text_style" style="text-align: center">твій персональний менеджер</span>
<!--        <div class="application-btn btn_green"-->
<!--             @click="typeOfCreditSelector('cash')"-->
<!--             :class="{ btn_green: typeOfLoan === 'cash'}"-->
<!--        >-->
<!--          онлайн чат-->
<!--        </div>-->
        <span class="support-title">+38 (044) 593-66-33</span>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'cashCalculator',
  components: {},
  mounted() {
    this.$emit('scrollTop');
    document.title = 'Сучасний факторинг|Кредитні продукти ';
    this.typeOfCreditSelector(this.typeOfLoan);
  },
  methods: {
    mounthRangeMin() {
      if (this.typeOfLoan === 'cash') {
        return 6
      }
      else {
        return 6
      }
    },
    mounthRangeMax() {
      if (this.typeOfLoan === 'cash') {
        return 18
      }
      else {
        return 12
      }
    },
    valueMin() {
      if (this.typeOfLoan === 'cash') {
        return 5000
      }
      else {
        return 1000
      }
    },
    valueMax() {
      if (this.typeOfLoan === 'cash') {
        return 50000
      }
      else {
        return 50000
      }
    },
    typeOfCreditSelector(type) {
      this.typeOfLoan = type;
      this.mounth_range = this.mounthRangeMin();
      this.money_range = this.valueMin();
      this.recalculate()
    },
    recalculate() {
      this.paymentCounter(this.money_range, this.mounth_range, this.typeOfLoan);
    },
    linkRedirector(link) {
      window.open(link, '_blank');
    },
    aboutUsHandler() {
      this.dropDownToggle = !this.dropDownToggle;
    },
    handlerClick(){
      if(this.mouseOver === true) return
      if(this.dropDownToggle === false) return
      if(this.dropDownToggle === true){
        this.dropDownToggle = false;
      }
    },
    mouseoverHandler() {
      this.mouseOver = true;
    },
    mouseLeaveHandler(){
      this.mouseOver = false;
    },
    paymentCounter(money, mounthQuantity, typeOfLoan) {
      const numberOfMonths = typeOfLoan === 'untilSalary' ? 1 : mounthQuantity;
      let percent;
      switch (typeOfLoan) {
        case 'cash':
          percent = 4
          break
        case 'good':
          percent = 7
          break
        default:
          percent = 52.5
          break
      }
      const generalCreditAmounth = typeOfLoan === 'cash' ? Number(money / 100 * 10) + Number(money) : Number(money / 100 * 15) + Number(money);
      const mouthlyComission = (generalCreditAmounth / 100 * percent);
      const mouthlyPayment = (generalCreditAmounth / numberOfMonths) + mouthlyComission;
      this.mouthlyPayment = Math.ceil(mouthlyPayment);
    },
    notificationOfUnnable() {
      this.isMoneyUntilSalary = true;
    },
    stepIdent() {
     return this.typeOfLoan === 'cash'? '6' : '1'
    }
  },
  data() {
    return {
      typeOfLoan: 'good',
      money_range: 5000,
      mounth_range: 6,
      mouthlyPayment: 0,
      isMoneyUntilSalary: false,
      dropDownToggle: false,
      mouseOver: false,
      minMounthQuantity: 3,
    }
  },
}
</script>

<style lang="scss" scoped>
.application-btn {
  height: 40px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 10px;
  background-color: transparent;
  transition: all .5s;
  color: #3b3b3b;
  border: 1px solid #12b423;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.btn_green {
  background-color: #12b423;
  color: #FEFEFE;
}

.application-btn:hover {
  color: #FEFEFE;
  background-color: #12b423;
  border: 1px solid #12b423;
  cursor: pointer;
}

.text_style {
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  margin: 5px 10px 5px 10px;

}

.text_style_bold {
  font-weight: 700;
  font-size: 45px;
}

.calc_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;

  .block_name {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    .messageWrap {
      box-shadow: 5px 5px 6px 0px rgb(0 0 0 / 10%);
      border-radius: 15px;
      padding: 5px 5px 5px 5px;
      display: flex;
      align-items: center;
      flex-direction: row;
      background-color: white;
      position: absolute;
      top: 210px;
      left: 10px;

      .infoMessage {
        color: #1f1f1f;
        font-size: 14px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 600;
        background-position: center center;
        text-align: center;

      }
      .attention_pic {
        width: 50px;
        height: 35px;
      }
    }
    .block_name-btn {
      display: flex;
      flex-direction: column;
      align-self: center;
      margin-top: 30px;

    }

    .support_wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 70px;
      max-width: 300px;
      width: 100%;
      max-height: 400px;
      height: 100%;

      .support_block {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .support-title {
          color: #000000;
          font-size: 18px;
          font-family: 'Montserrat', Arial, sans-serif;
          line-height: 1.55;
          font-weight: 700;
          background-position: center center;
          border-color: transparent;
          border-style: solid;
        }

        .photo_wrapper img {
          max-width: 150px;
          border-radius: 50%;
        }
      }
    }
  }

  .calk_block-container {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .calc_block {
      max-width: 600px;
      width: 95%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid green;
      border-radius: 15px;
      padding-left: 20px;
      margin-top: 30px;
      position: relative;
      .infoMessage{
        position: absolute;
        top:0;
        left: 0;
        z-index: 6;
        background-color: white;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        font-size: 18px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      .calc_block-inner{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        .t-range {
          -webkit-appearance: none; /* Override default CSS styles */
          appearance: none;
          width: 90%; /* Full-width */
          height: 3px; /* Specified height */
          background: #d3d3d3; /* Grey background */
          outline: none; /* Remove outline */
          opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
          -webkit-transition: .2s; /* 0.2 seconds transition on hover */
          transition: opacity .2s;
        }

        .slider:hover {
          opacity: 1; /* Fully shown on mouse-over */
        }

        .t-range::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          appearance: none;
          width: 15px; /* Set a specific slider handle width */
          height: 15px; /* Slider handle height */
          background: rgb(18, 180, 34);; /* Green background */
          cursor: pointer; /* Cursor on hover */
          border-radius: 50%;
        }

        .t-range::-moz-range-thumb {
          width: 5px; /* Set a specific slider handle width */
          height: 5px; /* Slider handle height */
          background: rgb(18, 180, 34);; /* Green background */
          cursor: pointer;
          border-radius: 50%;
        }
      }
    }
    .links_wrapper {
      display: flex;
      max-width: 600px;
      flex-wrap: wrap;
      margin-top: 30px;
      justify-content: space-around;

      .link_calk {
        display: flex;
        max-width: 140px;
        cursor: pointer;
        text-align: center;

        .link_image {
          max-width: 20px;
          height: 20px;
        }

        .link_title {
          color: #3b3b3b;
          font-size: 14px;
          font-family: 'Montserrat', Arial, sans-serif;
          line-height: 1.55;
          font-weight: 400;
          background-position: center center;
          border-color: transparent;
          border-style: solid;
        }
      }
    }
  }
}

.support_wrapper-bottom {
  display: none;
  justify-content: space-around;
  margin-top: 70px;
  max-width: 300px;
  width: 100%;
  max-height: 400px;
  height: 100%;

  .support_block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .support-title {
      color: #000000;
      font-size: 18px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.55;
      font-weight: 700;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
    }

    .photo_wrapper img {
      max-width: 150px;
      border-radius: 50%;
    }
  }
}
.dropDownMenu{
  background-color: #fdfff8;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  bottom: -50px;
  left: 0;
  z-index: 5;
  text-align: left;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 20%);
  .navLink{
    margin: 5px 10px 5px 10px;
  }
}

.navLink {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat', Arial, sans-serif;
  margin: 0 auto;
  text-align: left;
  cursor: pointer;
}
.navLink:hover{
  color:#1fd232;
}
// media
@media (max-width: 1060px) {
  .block_name {
    .messageWrap {
      top: 300px;

      .infoMessage{
        font-size: 10px;
        .attention_pic{

        }
      }
    }
}
}
@media (max-width: 1000px) {
  .calc_wrapper {
    flex-direction: column;
    margin: 20px 10px 20px 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .support_wrapper-bottom {
      display: flex;
    }

    .calk_block-container {
      margin: 0 auto;
      .calc_block{
        margin-top: 70px;
      }
    }

    .block_name {
      margin-top: 10px;
      margin-bottom: 10px;
      .messageWrap {
        .infoMessage{
          font-size: 14px;
        }
      }
      .support_wrapper {
        display: none;
      }
    }
  }

  .support_wrapper {
    flex-direction: column-reverse;

    .links_wrapper {
      align-self: center;
      display: flex;
      justify-content: center;

      .link_calk {
        margin: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  .calc_wrapper {
    margin: 20px 10px 20px 10px;

    .block_name {
      align-items: center;
      .messageWrap{
        .attention_pic{
          display: none;
        }
      }

      .text_style {
        text-align: center;
      }

      .block_name-btn {
        display: flex;
        flex-direction: column;
      }
    }
    .calk_block-container{
      .calc_block{
        margin-top: 100px;
      }
    }
  }
  .text_style_bold {
    font-size: 32px;
    text-align: center;
    line-height: 1.1;
  }
  .text_style {

  }

}

@media (max-width: 420px) {
  .calc_wrapper {
    margin: 20px 10px 20px 10px;
  }
}
</style>


