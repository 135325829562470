<template>
  <div class="main_wrapper">
    <span class="main_title">Як ми працюємо?</span>
    <span
        class="main2_title">

      Опис схеми роботи по оформленню кредиту на товар клієнтам фінансової компанії ТОВ «Сучасний Факторинг»

    </span>
    <div class="divider_horizontal"></div>
    <div class="arguments_ours">
      <div class="arguments_title">
        <span class="title_block">
          Ми співпрацюємо з більшістю роздрібних мереж України з продажу техніки та електроніки
        </span>
        <img
            class="t-img t-img1"
            data-original="https://static.tildacdn.com/tild3735-3037-4830-a564-666263376636/rozetka-paperplane.png"
            src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/rozetka-paperplane.png">
      </div>
      <div class="arguments_list">
        <span class="list_title">
          Отримати кредит на покупку товарів Ви можете в роздрібних магазинах наших партнерів
        </span>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Завітайте до одного з магазинів-партнерів або зайдіть на сторінку їх інтернет-магазину
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Оберіть бажаний товар
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Оформіть заявку на покупку товару у кредитного посередника компанії або просто зробіть замовлення на товар в інтернет-магазині з оплатою на умовах кредиту
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
        Якщо банки, представлені в магазинах, відмовлять Вам в кредитуванні, Ваша заявка на кредит потрапить до нас на розгляд
           </span>
        </div>

      </div>
    </div>
    <div class="divider_horizontal"></div>
    <div class="arguments_ours">
      <div class="arguments_title">
        <span class="title_block">
          Кредитуємо покупців, яким відмовили інші банки та фінансові компанії
        </span>
      </div>
      <div class="arguments_list">
        <span class="list_title">
         Заявка на кредит опрацьовується одразу з моменту отримання
        </span>

        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Розглядаємо заявки від 1 до 5 хвилин
        </span>
          </div>

      <div class="list_item_wrapper">
        <div class="square_element"></div>
        <span class="list_item">
         Використовуємо власні розробки в програмному забезпеченні, інтегровані в Українське бюро кредитних історій
        </span>
      </div>


    <div class="list_item_wrapper">
      <div class="square_element"></div>
      <span class="list_item">
         Надаємо ще одну можливість придбати бажаний товар в кредит, навіть після відмови від банків.
      </span>
    </div>
      </div>
    </div>
    <div class="divider_horizontal"></div>
    <div class="arguments_ours">
      <div class="arguments_title">
        <span class="title_block">
          Використовуємо інноваційні алгоритми в прийнятті рішень по заявкам
        </span>
        <img class="tn-atom__img t-img t-img3"
             alt="picture"
             data-original="https://static.tildacdn.com/tild6465-6132-4461-b936-393539323731/Heart.png"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/Heart.png">
      </div>
      <div class="arguments_list">
        <span class="list_title">
          Маємо унікальне програмне забезпечення для швидкого прийняття рішення по заявкам
        </span>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
        Ми використовуємо передові розробки з оцінки платоспроможності клієнтів. Наші алгоритми є унікальними для ринку України, завдяки чому ми маємо можливість допомагати клієнтам, яким відмовили інші фінансові установи.
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Ми інтегровані в систему
            <strong><a href="https://bank.gov.ua/ua/bank-id-nbu" target="_blank">BankID від НБУ</a></strong>
            , за рахунок чого Ви можете оформлювати кредит на товар онлайн без необхідності фізично приходити в магазин.
          </span>
        </div>
      </div>
    </div>


    <div class="divider_horizontal"></div>
    <div class="arguments_ours">
      <div class="arguments_title">
        <span class="title_block">
          Які документи необхідні для оформлення кредиту?
        </span>
      </div>
      <div class="arguments_list">
        <span class="list_title">
          Потрібен мінімальний пакет документів
        </span>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
        Для оформлення кредиту на товар Вам знадобляться Паспорт громадянина України та Ідентифікаційний номер
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
            Якщо при собі немає вказаних документів, Ви можете пройти ідентифікацію онлайн через систему <strong><a href="https://bank.gov.ua/ua/bank-id-nbu" target="_blank">BankID від НБУ</a>.</strong> Для цього Ви отримаєте посилання в SMS або в месенджери під час оформлення кредиту
          </span>
        </div>
      </div>
    </div>

    <div class="divider_horizontal"></div>
    <div class="arguments_ours">
      <div class="arguments_title">
        <span class="title_block">
          Забезпечуємо постійний супровід клієнта під час оформлення покупки
        </span>
      </div>
      <div class="arguments_list">
        <span class="list_title">
          Інформуємо клієнта про результат на всіх етапах розгляду заявки
        </span>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Надсилаємо повідомлення через SMS або в месенджери
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Телефонуємо для детальної консультації по умовам кредиту
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Повідомляємо про рішення та запрошуємо клієнта на оформлення в зручний для нього час
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Допомагаємо підписати паперовий або електронний кредитний договір
        </span>
        </div>
      </div>
    </div>
    <div class="divider_horizontal"></div>
    <div class="arguments_ours">
      <div class="arguments_title">
        <span class="title_block">
         Надаємо зручні та якісні можливості контролювати свій кредит
        </span>
        <img class="tn-atom__img t-img t-img3"
             alt="picture"
             data-original="https://static.tildacdn.com/tild6465-6132-4461-b936-393539323731/Heart.png"
             src="https://thumb.tildacdn.com/tild3434-6664-4364-b831-663761326232/-/resize/184x/-/format/webp/photo.png">
      </div>
      <div class="arguments_list">
        <span class="list_title">
            Ви маєте можливість переглядати інформацію по кредиту в особистому кабінеті
        </span>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Сплатити щомісячний платіж онлайн

        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Відслідкувати інформацію по платежам
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Ознайомитися з умовами договору
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Замовити довідку про відсутність заборгованості
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Поставити питання за допомогою чату або улюбленого месенджеру
        </span>
        </div>
        <div class="list_item_wrapper">
          <div class="square_element"></div>
          <span class="list_item">
         Сформувати  нову заявку на отримання кредиту
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.main_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  .main_title {
    color: #12b423;
    font-size: 45px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
  }

  .main2_title {
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 300;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
  }

  .divider_horizontal {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .arguments_ours {
    display: flex;

    .arguments_title {
      position: relative;
      //padding-left: 20px;
      //padding-right: 20px;
      max-width: 49%;
      width: 100%;

      .title_block {
        color: #000000;
        font-size: 20px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 700;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
      }

      .t-img {
        width: 70px;
        position: absolute;
        bottom: 10px;
        right: 15px;
      }

      .t-img3, .t-img4 {
        right: 145px;
        bottom: 30px;
      }
    }

    .arguments_list {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      padding-bottom: 30px;
      max-width: 49%;
      width: 100%;

      .text_inner {
        color: #000000;
        font-size: 16px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 400;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
      }

      .list_item_wrapper {
        display: flex;
      }

      .list_title {
        color: #000000;
        font-size: 16px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 600;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        margin-bottom: 20px;
      }

      .list_item {
        color: #000000;
        font-size: 16px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 400;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width: 80%;
      }

      .square_element {
        width: 15px;
        height: 5px;
        background-color: lightgrey;
        margin-top: 10px;
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 950px) {
  .arguments_ours {
    flex-direction: column;
    align-items: center;

    .arguments_title {
      .t-img {
        top: 0;
      }

      .t-img1 {
        display: none;
      }

      .t-img2 {
        left: -15%;
      }

      .t-img3 {
        left: 100%;
      }
    }
  }
}

@media (max-width: 650px) {
  .main_wrapper {
    .arguments_ours {
      flex-direction: column;
      align-items: center;
      width: 100%;

      .arguments_title {
        max-width: 80%;
        width: 100%;
      }

      .arguments_list {
        max-width: 80%;
        width: 100%;
      }

      .arguments_title {
        .t-img {
          top: 0;
        }

        .t-img2 {
          display: none;
        }

        .t-img3 {
          left: 50%;
          top: 70%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .main_wrapper {
    .main_title {
      font-size: 40px;
      text-align: center;
    }

    .main2_title {
      font-size: 14px;
      text-align: center;
    }

    .arguments_ours {
      flex-direction: column;
      align-items: center;
      width: 100%;

      .arguments_title {
        max-width: 80%;
        width: 100%;
      }

      .arguments_list {
        max-width: 80%;
        width: 100%;
      }

      .arguments_title {
        .t-img {
          top: 0;
        }

        .t-img2 {
          display: none;
        }

        .t-img3 {
          display: none;
        }
      }
    }
  }
}

</style>
