<template>
  <div class='frequent_questions-container'>
    <div class="bold_title">Поширені питання</div>
    <div class="question_blockWrapper">


      <div class="question_block_container"
           v-for="(question, index) in questions" :key="index"
      >
        <div class="question_block"
             style="border-top:1px solid lightslategrey"
             @click="minusClickHandler(index)"
        >
          <div style="max-width: 90%"
               class="question_text"
          >
            {{ question.q_text }}
          </div>
          <div class="icon_wrapper">
            <CloseIcon class="closeIcon"/>
          </div>
        </div>
        <transition name="fade">
          <div class="downContent"
               v-if="showDropDown === index"
          >
            <p v-html="question.q_answer"></p>
          </div>
        </transition>
      </div>

    </div>
  </div>

</template>
<script>
import CloseIcon from "vue-material-design-icons/Plus.vue";

export default {
  name: 'FrequentlyQuestions',
  components: {
    CloseIcon
  },
  methods: {
    minusClickHandler(index) {
      if (index === this.showDropDown) {
        this.showDropDown = null;
      } else {
        this.showDropDown = index;
      }
    },
  },
  data() {
    return {
      showDropDown: null,
      questions: [
        {
          q_text: 'Як замовити довідку про закриття договору?',
          q_answer: '<strong>Для замовлення довідки Ви можете скористатися одним із варіантів:</strong><br> ' +
              ' <strong>1.</strong> Замовлення довідки доступне через особистий кабінет компанії за посиланням ' +
              '<strong><a href="https://auth.sfactor.com.ua">https://auth.sfactor.com.ua/</a></strong>.' +
              '  В розділі «Історія».<br> ' +
              '<strong>2.</strong> Замовити довідку можна написавши лист на корпоративну пошту компанії' +
              '  <strong><a href="mailto:info@sfactor.com.ua">info@sfactor.com.ua</a></strong><br>' +
              '<strong>3.</strong> Зателефонуйте за номером <strong><a href="tel:+380445936633">044-593-66-33</a></strong>,' +
              ' наш консультант допоможе Вам замовити довідку.<br>' +
              '<strong>4.</strong> Зверніться в  чат бот (<a href="viber://pa?chatURI=sfactor_bot">Viber</a> і <a href="https://t.me/sfactor_bot">Telegram</a>), спеціалісти допоможуть Вам замовити довідку про закриття кредиту.<br>' +
               '<span style="color:red"><strong> 5. Зверніть увагу! Термін виготовлення довідки становить до 30 рабочих днів від дня запиту.</strong></span><br>',
        },
        {
          q_text: 'Як увійти в особистий кабінет?',
          q_answer: 'Для входу в особистий кабінет перейдіть за посиланням ' +
              '<strong><a href="https://auth.sfactor.com.ua/">https://auth.sfactor.com.ua/.</a></strong> <br>Логін — це ваш номер телефону,' +
              ' вказаний під час реєстрації. Пароль — вказаний під час реєстрації.' +
              ' Для підтвердження номера телефону введіть унікальний цифровий' +
              ' ідентифікатор. Ви будете отримувати його в SMS під час кожної' +
              ' спроби авторизації в особистий кабінет.',
        },
        {
          q_text: 'Що робити, якщо не приходить SMS із кодом?',
          q_answer: '<br><strong>1.</strong>     Уважно перевірте коректність телефонного номера (на екрані зазначено той, на який надіслано SMS). У разі помилки змініть номер (натисніть відповідну кнопку) і подайте запит на повторну відправку коду.\n' +
              '<br><strong>2.</strong>     Якщо код не надходить, спробуйте перезавантажити мобільний телефон. Зачекайте декілька хвилин і повторіть процедуру.\n' +
              '<br><strong>3.</strong>      Якщо код все одно не надходить, можливо, на телефоні недостатньо пам’яті для отримання нових повідомлень. Спробуйте очистити історію SMS свого телефону.\n' +
              '<br><strong>4.</strong>     Якщо перераховані вище дії не допомогли, напишіть нам в онлайн-чат або' +
              ' зверніться до служби підтримки за номером <strong><a href="tel:+380445936633">044-5936633.</a></strong>',
        },
        {
          q_text: 'Як змінити номер телефону?',
          q_answer: 'Зателефонуйте за номером <strong><a href="tel:+380445936633">044-5936633.</a></strong> Консультанти нашої' +
              ' служби підтримки з радістю допоможуть вам у розв’язанні питання.' +
              ' Заявки на зміну номера телефону розглядаються виключно в робочий час:' +
              ' понеділок — неділя, 9:00-19:30. Процедура зміни номера розроблена для' +
              ' вашої безпеки. Дякуємо за розуміння!',
        },
        {
          q_text: 'Як дізнатись залишок по договору?',
          q_answer: '<br><strong>1.</strong> Залишок по кредитному договору можна побачити в особистому кабінеті, за посиланням' +
              ' <strong><a href="https://auth.sfactor.com.ua/">https://auth.sfactor.com.ua/.</a></strong>' +
          '<br><strong>2.</strong> Зателефонуйте за номером  <strong><a href="tel:+380445936633">044-5936633.</a></strong> Консультанти нашої служби підтримки з радістю проінформують про Ваш залишок по кредиту.'+
          '<br><strong>3.</strong> Зверніться в  онлайн - чат (<a href="viber://pa?chatURI=sfactor_bot">Viber</a> і <a href="https://t.me/sfactor_bot">Telegram</a>), спеціалісти повідомлять Вам залишок по кредитному договору.'
        },
        {
          q_text: 'Оплатили платіж, а залишок в особистому кабінеті компанії не змінився.',
          q_answer: 'Зверніть увагу, що платежі зараховуються від 2 до 5 робочих днів.' +
              ' Також інформація в особистому кабінеті оновлюється один раз на добу.' +
              ' При необхідно, перезайдіть у Ваш особистий кабінет за' +
              ' посиланням  <strong><a href="https://auth.sfactor.com.ua/">https://auth.sfactor.com.ua/.</a></strong>',
        },
        {
          q_text: 'Як погасити кредит?',
          q_answer: 'Є декілька способів, як погасити кредит або сплатити мінімального платежу згідно з графіком.'+
          '<br><strong>1.</strong> В особистому кабінеті за допомогою картки будь-якого українського банку,' +
              ' та сервісу Apple Pay (Google Pay). Оплата без додаткових комісій,' +
              ' кошти зараховуються протягом 3 годин.'+
    '<br><strong>2.</strong> Через мережу терміналів «ПриватБанку» (розділ «Комунальні платежі»)' +
              ' або «Приват24» (розділ «Мої платежі»). Шукайте нас за кодом 35310044 (ТОВ «Сучасний Факторинг»).' +
              ' <br>У призначенні платежу вкажіть свій номер договору і ІПН.'+
    '<br><strong>3.</strong> Банківським переказом у касі будь-якого банку України.' +
        ' Наші реквізити: Отримувач ТОВ «Сучасний Факторинг» Банківські реквізити:' +
        ' IBAN UA463808380000026509799999969 UAH (п/р № 26509799999969),»ПРАВЕКС-БАНК»,МФО 380838,ЄДРПОУ 35310044. або' +
        ' IBAN UA893348510000000000265011544 UAH (п/р № 265011544) АТ «ПУМБ»,МФО 334851,ЄДРПОУ 35310044.' +
        ' Призначення платежу: «Плата за кредитним договором №_________ від (ПІБ)».' +
    '<br><span style="color:red">Зверніть увагу: банки можуть стягувати додаткову комісію, а платіж може надійти на наш рахунок'+" "+
    'протягом декількох днів. Згідно з умовами договору датою платежу вважається дата зарахування грошових коштів на наш рахунок.</span><br>' +
    '<br><strong>4.</strong> Через термінали IBox. Для цього необхідно: вибрати у категорії «Банківські послуги» категорія ' +
        '«Банки та фінансові послуги» - компанія «Сучасний факторинг» - ввести номер договору і сплатити бажану суму.'
        },
        {
          q_text: 'Чи можливо погасити кредит раніше строку?',
          q_answer: 'Усі клієнти можуть погашати кредити/позики раніше терміну,' +
              ' при цьому відсоток нараховується тільки за фактичний час' +
              ' користування кредитними коштами. ',
        },
        {
          q_text: 'Як закрити кредит?',
          q_answer: 'Кредит закривається автоматично,' +
              ' достатньо дізнатися залишок і після сплати ' +
              'через два дні перевірити статус закриття в' +
              ' особистому кабінеті компанії, або звернутися ' +
              'за номером компанії  <strong><a href="tel:+380445936633">044-5936633.</a></strong>',
        },
        {
          q_text: 'До якого числа потрібно сплачувати?',
          q_answer: 'Дата сплати кредиту – вказана в графіку платежів, рекомендуємо сплачувати за 5 днів до вказаної дати.',
        },
        {
          q_text: 'Як повернути помилково сплачені кошти або невірне зарахування?',
          q_answer: 'Для повернення надлишково сплачених коштів / зарахування платежу за договором.' +
              '<br><strong>1.</strong> Зателефонуйте за номером  <strong><a href="tel:+380445936633">044-5936633.</a></strong> Консультанти нашої служби підтримки з відправлять Вам заяву,' +
              ' для зарахування (або повернення) коштів.' +
              '<br><strong>2.</strong> Звернутися в онлайн – чат. Вам буде відправлено шаблон заяви.' +
              '<br><strong>3.</strong> Необхідно звернутися на електронну пошту компанії  <strong>' +
              '<a href="mailto:info@sfactor.com.ua">info@sfactor.com.ua</a></strong>  ' +
              'Вам буде надіслана заява на повернення коштів.' +
              ' До заяви додаєте квитанцію про сплату.' +
              '<br><strong><span style="color:red">' +
              'Зверніть увагу: компанія розгляне Ваше звернення, кошти будуть перераховані протягом 14 днів.' +
              '<span></strong>',
        },
        {
          q_text: 'Куди мені звертатись у разі неможливості виконати свої зобов\'язання вчасно?',
          q_answer: 'В разі виникнення питань щодо врегулювання простроченої заборгованості' +
              ' ви можете звертатися за номером телефону відділу врегулювання простроченої' +
              ' заборгованості: <strong><a href="tel:+380445936633">044-3550920</a></strong> з 09:00 до 19:00 з пн-пт, вих: сб, нд, а також' +
              ' святкові дні.',
        },
        {
          q_text: 'Що буде, якщо я не сплачую  кредит вчасно?',
          q_answer: 'У разі умисного ухилення від виплати кредиту,' +
              ' компанія починає процес стягнення боргу в примусовому' +
              ' порядку через суд. Крім того, інформація, про Вас і Вашу' +
              ' заборгованість буде передано, в тому числі і в Кредитне Бюро' +
              ' України, що в подальшому негативно вплине на Ваші майбутні спроби' +
              ' оформити кредит у всіх банках і фінансових установах країни.',
        },
        {
          q_text: 'Чому після сплати не зменшується залишок?',
          q_answer: 'Ваш платіж буде відображатись на авансовому рахунку та покриє тіло кредиту після розрахункової дати.',
        },
        {
          q_text: 'Після оплати рахунку було знято більше грошей. Що робити?',
          q_answer: 'Ми ніколи не беремо комісію за погашення кредиту.' +
              ' Рекомендуємо звернутися в Ваш банк і уточнити тарифи обслуговування' +
              ' - можливо, банк віднімає комісію за розрахунки карткою. Крім того,' +
              ' Ви можете дізнатися про рух коштів по рахунку, попросивши виписку' +
              ' з Вашого банку.',
        },
        {
          q_text: ' Як пройти ідентифікацію через систему BankID?',
          q_answer:
              '<strong><br/>1.<strong>Коли Ваша заявка на кредит потрапить до нас на розгляд, то Ви отримаєте в SMS або в месенджери посилання для проходження ідентифікації. \n' +
              '<strong><br/>2.<strong>Щоби перейти до ідентифікації через систему BankID НБУ натисніть кнопку з логотипом BankID. Дана послуга є безкоштовною для користувача.\n<br/><br/>' +
              ' <img src="https://apextest12.b-cdn.net/12.png" alt="firstPage"/><br/>' +
              '3.У новому вікні оберіть банк, в якому маєте рахунок, та пройдіть автентифікацію на сторінці або' +
              ' застосунку банку. Перевірте дані та надайте вашу згоду на їх передачу.' +
              '<img src="https://apextest12.b-cdn.net/13.png" alt="secondPage"/><br/>' +
              '4.Після успішної ідентифікації Ви автоматично повернетеся на сторінку отримання послуги де Вам відобразиться остаточне рішення по ній (на знімку екрана приклад позитивного рішення).<br/>' +
              '<img src="https://apextest12.b-cdn.net/15.png" alt="thirdPage"/> '

        },
      ],
    }
  }
}
</script>
<style lang="scss" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}

.frequent_questions-container {
  display: flex;
  max-width: 1400px;
  width: 98%;
  margin-top: 50px;
  justify-content: space-between;

  .question_blockWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

  }

  .question_block_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 700px;
    width: 100%;

    .downContent {
      width: 100%;
      font-family: 'Montserrat', Arial, sans-serif;
      font-weight: 300;
      color: #000;
      font-size: 14px;
      line-height: 1.55;
    }
  }

  .question_block {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Montserrat';
    color: #000;
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .question_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .icon_wrapper {

      padding: 2px;
      display: inline;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      position: relative;
      align-self: center;

    }

    .closeIcon {
      height: 20px;
      width: 20px;
      text-align: center;
      padding-right: 4px;
      padding-bottom: 4px;
      transition: all .7s;
      z-index: 5;
      position: absolute;
      left: 5px;
      top: 5px;
    }

    .closeIcon:hover {
      transform: rotate(133deg);
    }
  }

  .question_block:hover {
    .icon_wrapper {
      background-color: #12b423;
      color: white;
    }
  }
}

.bold_title {
  color: #000;
  font-size: 45px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 700;
  background-position: center center;
  margin-bottom: 80px;
}

@media (max-width: 720px) {
  .frequent_questions-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 500px) {

  .frequent_questions-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .question_block {
      max-width: 100%;

      .question_text {
        font-size: 18px;
      }
    }
    .bold_title {
      font-size: 40px;
      text-align: center;
    }
  }
}
</style>
