<template>
  <div class="SafetyGuarantee_wrapper">
<span class="garantee_title">
  Ми піклуємось про вашу безпеку
</span>
    <div class="garantee_items-container">
      <div class="item_divider"></div>
      <div class="item_text"
           data-aos="fade-up"
           data-aos-delay="100"
           data-aos-duration="200"
      >
        Чесно говоримо про умови позики — без прихованих платежів та додаткових комісій
      </div>
      <div class="item_divider"></div>
      <div class="item_text"
           data-aos="fade-up"
           data-aos-delay="200"
           data-aos-duration="200"
      >
        Несемо відповідальність за збереження ваших даних
      </div>
      <div class="item_divider"></div>
      <div class="item_text"
           data-aos="fade-up"
           data-aos-delay="300"
           data-aos-duration="200"
      >
        Дотримуємося законів про захист ваших прав користувача
      </div>
      <div class="item_divider"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.SafetyGuarantee_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  max-width: 1400px;
  width: 95%;
  .garantee_title{
    color: #ffffff;
    font-size: 45px;
    font-family: 'Montserrat',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 600;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    text-align: center;
  }
  .garantee_items-container{
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .item_text{
      color: #ffffff;
      font-size: 16px;
      font-family: 'Montserrat',Arial,sans-serif;
      line-height: 1.55;
      font-weight: 300;
      background-position: center center;
      max-width: 250px;
    }
   .item_divider{
     height: 250px;
     width: 2px;
     background-color: #12b423;
   }
  }
}
@media (max-width: 950px) {
  .SafetyGuarantee_wrapper {
    .garantee_title{
      font-size: 40px;
    }
    .garantee_items-container{
      flex-direction: column;
      align-items: center;
      .item_divider{
        height: 2px;
        width: 250px;
        background-color: #12b423;
        margin: 30px 0px 30px 0px;
      }
      .item_text{
        text-align: center;
        max-width: 60%;
      }
    }
  }
}
@media (max-width: 950px) {
  .SafetyGuarantee_wrapper {
    .garantee_title{
      font-size: 35px;
    }
  }

}
</style>
