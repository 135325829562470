import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/views/HomeView";
import cashCredit from "@/views/cashCredit";
import goodCredit from "@/views/goodCredit";
import aboutPage from "@/views/aboutPage";
import LawPage from "@/views/LawPage";
import MenuPage from "@/views/MenuPage";
import NewsPage from "@/views/NewsPage";
import creditCalculator from "@/views/creditCalculator";
import policePage from "@/views/policePage";
import contactsPage from "@/views/contactsPage";
import FAQpage from "@/views/FAQpage";



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cash',
    name: 'cash',
    component: cashCredit
  },
  {
    path: '/good',
    name: 'good',
    component: goodCredit
  },
  {
    path: '/about',
    name: 'about',
    component: aboutPage
  },
  {
    path: '/law',
    name: 'law',
    component: LawPage
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuPage
  },
  {
    path: '/news',
    name: 'news',
    component: NewsPage
  },
  {
    path: '/creditCalculator',
    name: 'creditCalculator',
    component: creditCalculator
  },
  {
    path: '/police',
    name: 'police',
    component: policePage
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: contactsPage
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQpage
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/MailPage.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
