<template>
  <div class="goodC_wrapper">
    <div class="goodC_title">
  <span class="g_title1">
    Кредит на товар <span class="g_title1_hyphen">—</span>
  </span>
      <span class="g_title2">
     це просто
  </span>
      <span class="g_title3">
    Дозволь собі більше!
  </span>
    </div>
    <div class="placeHolder_for_copter">
    <div class="goodC_copter">
      <div class="copterWrapper">
        <img class="copter"
             data-original="https://static.tildacdn.com/tild3938-6233-4262-b830-646138666237/27870_Converted-0123.png"
             imgfield="tn_img_1642085308514"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/27870_Converted-0123.webp">
        <img class="copter_box"
             data-original="https://static.tildacdn.com/tild6564-3339-4861-b436-663538656334/44.png"
             imgfield="tn_img_1642085308521"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/44.webp">
      </div>
    </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.goodC_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  height: 500px;
 justify-content: space-between;
  .goodC_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .g_title1 {
      color: #ffffff;
      font-size: 53px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.1;
      font-weight: 700;
    }
    .g_title2 {
      color: #ffffff;
      font-size: 53px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.1;
      font-weight: 300;
    }

    .g_title3 {
      color: #ffffff;
      font-size: 22px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.55;
      font-weight: 300;
    }
  }
  .goodC_copter{
    animation: MoveUpDown 5s linear infinite;
    position: absolute;
    left: 0;
    top: 0;

    .copterWrapper{


      .copter{width: 350px}
      .copter_box{
        width: 150px;
        transform: rotate(-25deg);
        position: absolute;
        bottom: -20px;
        left: 23%;

      }
    }
  }
  .placeHolder_for_copter{
    display: flex;
    position: relative;
    height: 100px;
    width: 300px;
    margin-top: 30px;
    margin-right: 50px;
  }
}
@keyframes MoveUpDown {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 50px;
  }
}

@media (max-width: 960px) {
  .goodC_wrapper {
    height: 500px;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    .placeHolder_for_copter{
      margin-top: 0;
    }
    .goodC_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
      .g_title1 {
        color: #ffffff;
        font-size: 50px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.1;
        font-weight: 700;
        .g_title1_hyphen{
          display: none;
        }
      }
      .g_title2 {
        color: #ffffff;
        font-size: 50px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.1;
        font-weight: 300;
      }

      .g_title3 {
        color: #ffffff;
        font-size: 20px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 300;
      }
    }
    .goodC_copter{
      .copterWrapper{


        .copter{
          width: 200px
        }
        .copter_box{
          width: 70px;
          transform: rotate(-25deg);
          position: absolute;
          bottom: -20px;
          left: 23%;

        }
      }
    }
    .placeHolder_for_copter{

}}}
@media (max-width: 520px) {
  .goodC_wrapper {
    height: 500px;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .placeHolder_for_copter {
      margin-right: 0px;
    }
    .goodC_title {
      .g_title1 {
        font-size: 40px;
        .g_title1_hyphen {
          display: none;
        }
      }

      .g_title2 {
        color: #ffffff;
        font-size: 50px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.1;
        font-weight: 300;
      }

      .g_title3 {
        font-size: 20px;
      }
    }
  }
}
</style>
