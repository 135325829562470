<template>
  <div class="FAQ__container" >
    <FrequentlyAskedQuestions/>
  </div>
</template>
<script>
import FrequentlyAskedQuestions from "@/components/common/FrequentlyAsked_questions/FrequentlyAskedQuestions.vue";

export default {
  name: 'FaQ',
  components:{
    FrequentlyAskedQuestions
  }
}
</script>
<style lang="scss" scoped>
.FAQ__container{
  margin: 0;
  padding-left: 10px;
}
</style>
