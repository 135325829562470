<template>
  <div class="container_wrapper">
    <div class="good_inner-container">
      <ChooseProductBlock/>
    </div>
    <div class="good_inner-container">
      <WeAreReliable/>
    </div>
    <div class="good_inner-container" style="background-color: #020702">
      <OurPartners/>
    </div>
    <div class="good_inner-container" style="background-color: #f7faf7">
      <feedBacks/>
      </div>
<!--    <div class="good_inner-container" >-->
<!--      <FrequentlyAskedQuestions/>-->
<!--    </div>-->
    <div class="good_inner-container" >
      <ContactsBlock/>
    </div>
    <div class="good_inner-container">
      <AlertMessageBlock />
    </div>
    <div class="good_inner-container" >
      <FeedbackForm/>
    </div>
  </div>
</template>

<script>
import 'swiper/css';
import 'swiper/css/navigation';
import AOS from "aos";
import FeedBacks from '@/components/common/feedBacks/FeedBacks'
import OurPartners from '@/components/common/OurPartners/OurPartners'
import WeAreReliable from '@/components/common/WeAreReliable/WeAreReliable'
import ChooseProductBlock from "@/components/common/ChooseProductBlock/ChooseProductBlock";
import ContactsBlock from "@/components/common/contacts/ContactsBlock"
import FeedbackForm from "@/components/common/FeedbackForm/FeedbackForm";
import AlertMessageBlock from "@/components/common/AlertMessageBlock/AlertMessageBlock.vue";



AOS.init();
export default {
  name: 'HomeView',
  components: {
    AlertMessageBlock,
    FeedbackForm,
    ChooseProductBlock,
    FeedBacks,
    OurPartners,
    WeAreReliable,
    ContactsBlock,
  },
  mounted() {
    this.$emit('scrollTop');
    document.title = 'Сучасний факторинг|Головна ';
  },
  data() {
    return {}
  },

};

</script>
<style lang="scss" scoped>


.container_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.good_inner-container{
  display: flex;
  justify-content: center;
}

</style>


