<template>
  <div class="goodCredit_wrapper">
    <div class="good_inner-container">
      <LawInformation/>
    </div>
  </div>
</template>
<script>
import LawInformation from './../components/common/LawInformation/LawInformation'

export default {

  name: 'good_credit',
  components: {
    LawInformation,
  },
  data() {
    return {}
  },
  mounted() {
    this.$emit('scrollTop');
    document.title = 'Сучасний факторинг|Правова інформація ';
  },
}
</script>
<style lang="scss" scoped>
.goodCredit_wrapper{
  display: flex;
  width: 100%;
  flex-direction: column;
  .good_inner-container{
    display: flex;
    justify-content: center;
  }
}
</style>
