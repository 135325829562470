<template>
  <div class="container_wrapper">
  <div class="good_inner-container" >
    <ContactsBlock/>
  </div>
  <div class="good_inner-container" >
    <FeedbackForm/>
  </div>
  </div>
</template>
<script>
import ContactsBlock from "@/components/common/contacts/ContactsBlock";
import FeedbackForm from "@/components/common/FeedbackForm/FeedbackForm";

export default {
  components:{
    ContactsBlock,
    FeedbackForm
  },
  mounted() {
    this.$emit('scrollTop');
    document.title = 'Сучасний факторинг|Контакти ';
  },
}
</script>

<style lang="scss" scoped>
.container_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.good_inner-container{
  display: flex;
  justify-content: center;
}
</style>
