<template>
  <div class="goodCredit_wrapper">
    <div class="good_inner-container" style="background-color: #12b423">
       <SimpleGood/>
    </div>
    <div class="good_inner-container" style="background-color: #020702">
      <OurPartners/>
    </div>
    <div class="good_inner-container">
      <HowDoWeWorking/>
    </div>
<!--    <div class="good_inner-container" style="background-color: rgba(18,180,35,0.95);">-->
<!--      <paySimple/>-->
<!--    </div>-->
<!--    <div class="good_inner-container">-->
<!--      <FeedBacks/>-->
<!--    </div>-->
<!--    <div class="good_inner-container">-->
<!--      <FrequentlyAskedQuestions/>-->
<!--    </div>-->
    <div class="good_inner-container" >
      <ContactsBlock/>
    </div>
    <div class="good_inner-container" >
      <FeedbackForm/>
    </div>
  </div>
</template>
<script>
import SimpleGood from './../components/common/SimpleGood/SimpleGood'
import OurPartners from './../components/common/OurPartners/OurPartners'
import HowDoWeWorking from './../components/common/HowDoWeWorking/HowDoWeWorking'
import ContactsBlock from "@/components/common/contacts/ContactsBlock";
import FeedbackForm from "@/components/common/FeedbackForm/FeedbackForm";


export default {

  name: 'good_credit',
  components: {
    SimpleGood,
    OurPartners,
    HowDoWeWorking,
    ContactsBlock,
    FeedbackForm
  },
  mounted() {
    this.$emit('scrollTop');
    document.title = 'Сучасний факторинг|Кредит на товар  ';
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.goodCredit_wrapper{
  display: flex;
  width: 100%;
  flex-direction: column;

  .good_inner-container{
    display: flex;
    justify-content: center;
  }
}
</style>
