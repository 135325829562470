<template>
  <nav class="navContainer">
    <ul class="routerLiWrapp">
      <li class="navLi">
        <router-link class="routerLink"
                          to=""
                     @click="aboutUsHandler"
                     @blur="handlerClick"
                     >
          Про нас
        </router-link>
      </li>
      <ul class="dropDownMenu"
          @mouseover="this.mouseoverHandler"
          @mouseleave="this.mouseLeaveHandler"
          v-if="dropDownToggle">
        <li class="navLi"
            @click="dMenu_handler">
          <router-link class="routerLink" to="/about">Про нас</router-link>
        </li>
        <li class="navLi" @click="dMenu_handler">
          <router-link class="routerLink" to="/law">Правова інформація</router-link>
        </li>
        <li class="navLi" @click="dMenu_handler">
          <router-link class="routerLink" to="/contacts">Контакти</router-link>
        </li>
      </ul>
      <li class="navLi">
        <router-link to="/creditCalculator" class="routerLink">Кредитні<br/> продукти</router-link>
      </li>
      <li class="navLi">
        <router-link class="routerLink" to="/cash">Кошти в кредит</router-link>
      </li>
      <li class="navLi">
        <router-link class="routerLink"  to="/good">Кредит на товар</router-link>
      </li>
      <li class="navLi">
        <router-link class="routerLink"  to="/FAQ">Поширені<br/> питання</router-link>
      </li>
      <li class="navLi">
        <router-link class="routerLink" to="/news">Новини</router-link>
      </li>
    </ul>
  </nav>
</template>


<script>

export default {
  name: 'ProjectNavigation',
  data(){
    return {
      dropDownToggle: false,
      mouseOver: false,
    }
  },
  components: {},
  methods: {
    aboutUsHandler() {
      this.dropDownToggle = !this.dropDownToggle;
    },
    dMenu_handler() {
      this.dropDownToggle = false;
    },
    handlerClick(){
      if(this.mouseOver === true) return
      if(this.dropDownToggle === false) return
      if(this.dropDownToggle === true){
        this.dropDownToggle = false;
      }
    },
    mouseoverHandler() {
      this.mouseOver = true;
    },
    mouseLeaveHandler(){
      this.mouseOver = false;
    },


  },
}
</script>
<style lang="scss" scoped>
.navContainer{
  display: flex;
  flex-wrap: wrap;
}
.navLi{
  color: #3b3b3b;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Montserrat';
  list-style: none;
  margin-right: 25px;
  text-align: center;
  .routerLink{
    color:#3b3b3b;
  }
  .routerLink:hover{
    color:#12b422;
  }
}
.routerLiWrapp {
  position: relative;
  align-items: center;
}
.routerLiWrapp{
  width: 100%;
  display: flex;
}
.dropDownMenu{
  background-color: #fdfff8;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 10px;
  top: 45px;
  left: 0;
  align-items: center;
  z-index: 5;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 20%);
  .navLi{
    color: #3b3b3b;
    font-size: 15px;
    font-weight: 400;
    font-family:'Montserrat', Arial, sans-serif;
    list-style: none;
    margin: 5px 20px 5px 20px;
    .routerLink{
      color:#3b3b3b;
    }
    .routerLink:hover{
      color:#12b422;
    }
  }
}
.dropDownMenu:before{
  position:absolute;
  content:"";
  left:40px;
  top:-10px;
  transform: scalex(0.8) rotate(135deg);
  width:30px;
  height:30px;
  background-color: #fdfff8;
}
</style>

