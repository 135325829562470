<template>
 <div class="cashCredit_wrapper">
   <creditCalc/>
   <div class="credit_requirements-container" >
   <creditRequirements/>
  </div>
   <div class="credit_requirements-container" style="background-color: transparent" >
     <ourBenefits/>
   </div>
   <div class="credit_requirements-container" style="background-color: black" >
     <howToAchieve/>
   </div>
   <div class="credit_requirements-container" style="background-color: rgba(18,180,35,0.95);" >
     <paySimple/>
   </div>
   <div class="credit_requirements-container">
     <FeedBacks/>
   </div>
   <div class="credit_requirements-container" style="background-color: black">
     <SafetyGuarantee/>
   </div>
   <div class="good_inner-container" >
     <ContactsBlock/>
   </div>
 </div>
</template>
<script>
import creditCalc from '@/components/common/cashCalculator/cashCalculator'
import creditRequirements from '@/components/common/creditRequirements/creditRequirement'
import ourBenefits from '@/components/common/ourBenefits/ourBenefits'
import howToAchieve from '@/components/common/howToAchieve/howToAchieve'
import paySimple from '@/components/common/paySimple/paySimple'
import FeedBacks from '@/components/common/feedBacks/FeedBacks'
import SafetyGuarantee from '@/components/common/SafetyGuarantee/SafetyGuarantee'
import ContactsBlock from '@/components/common/contacts/ContactsBlock'




export default {
  name: 'cash_credit',
  components: {
    creditCalc,
    creditRequirements,
    ourBenefits,
    howToAchieve,
    paySimple,
    FeedBacks,
    SafetyGuarantee,
    ContactsBlock
  },
  mounted() {
    this.$emit('scrollTop');
    document.title = 'Сучасний факторинг|Кошти в кредит ';
  },
  data() {
    return {
      count: 0
    }
  }
}
</script>
<style lang="scss" scoped>
.cashCredit_wrapper{
  display: flex;
  width: 100%;
  flex-direction: column;
  .credit_requirements-container{
    background-color: #f1edf3;
    display: flex;
    justify-content: center;
  }
}
</style>
