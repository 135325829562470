<template>
  <div style="width: 96%;
                    margin-top: 100px;">
    <div class="feedback_form">
<!--      <div class="site_ordinary_text"-->
<!--           style="font-size: 14px;-->
<!--              max-width:40%;-->
<!--              margin-bottom: 50px">-->
<!--        Якщо ви маєте ділову пропозицію або інші питання,-->
<!--        будь ласка, заповніть форму, щоб зв'язатися з нами-->
<!--      </div>-->
<!--      <img class="tn-atom__img" src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/image33.svg">-->
<!--      <div class="form_container">-->
<!--        <div class="form_wrapper">-->
<!--          <div class="allForms">-->
<!--        <input class="feedback_input" placeholder="Ваш e-mail:"/>-->
<!--        <input class="feedback_input" placeholder="Ваше ім'я:"/>-->
<!--        <input class="feedback_input" placeholder="Ваш телефон:"/>-->
<!--          </div>-->
<!--        <div class="application-btn btn_green">Відправити</div>-->
<!--        </div>-->
<!--        <div class="form_agreements">-->
<!--          Натискаючи на кнопку, ви даєте згоду на обробку персональних даних та погоджуєтеся з-->
<!--          <router-link to="/police"  style="color:red;cursor: pointer">політикою конфіденційності</router-link>-->
<!--        </div>-->
<!--      </div>-->
   <p class="feedBackFormText">
     Якщо у вас з'явилися запитання щодо роботи нашого сервісу або виникли скарги,
     будь ласка, надішліть лист на електронну пошту
     <strong><a href="mailto:info@sfactor.com.ua">info@sfactor.com.ua.</a></strong> Обов’язково
     вкажіть Ваше ПІБ, номер телефону та запитання чи скаргу, що у Вас виникли.
     Ми з радістю допоможемо вам.
   </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.feedback_form {
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #ffffff;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  box-shadow: 5px 5px 6px 0px rgb(0 0 0 / 10%);
  position: relative;
  padding-right: 10px;
  padding-left: 10px;

  .form_container {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    flex-wrap: wrap;
    .form_wrapper{
      width: 100%;
      display: flex;
      justify-content: space-between;

      .allForms{
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

      }
    }

    .feedback_input {
      max-width: 300px;
      min-width: 250px;
      width: 100%;
      outline: 0;
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
      background-color: transparent !important;
      border-radius: 0 !important;
      border-bottom: 1px solid lightslategrey;
      font-size: 17px;
      font-weight: 600;
      height: 50px;
      color: #383737;
      font-family: 'Montserrat', Arial, sans-serif;
    }

  }

  .form_agreements {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Montserrat', Arial, sans-serif;
    margin: 0px auto;
    opacity: 0.7;
    text-align: center;
    margin-bottom: 20px;
  }

  .tn-atom__img {
    width: 50px;
    position: absolute;
    top: 60px;
    left: 0;
  }
  .feedBackFormText{
    color: #000000;
    font-size: 22px;
    font-family: "Montserrat", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 300;
    border-color: transparent;
    background-position: center center;
    border-style: solid ;
    vertical-align: middle;
    width: 100%;
    text-align: center;
  }
}
.site_ordinary_text {
  color: #000000;
  font-size: 20px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
}
.application-btn {
  height: 40px;
  font-size: 16px;
 // margin: 20px 0 10px 10px;
  background-color: transparent;
  transition: all .5s;
  color: #3b3b3b;
  border: 1px solid #12b423;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 20px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 30px;
  letter-spacing: 1.2px;
 max-width: 150px;
  cursor: pointer;
}

.btn_green {
  background-color: #12b423;
  color: #FEFEFE;
}

.application-btn:hover {
  color: #FEFEFE;
  background-color: #12b423;
  border: 1px solid #12b423;
}
@media (max-width: 1220px) {
  .form_wrapper{
    flex-direction: column;
  }
  .application-btn{
    margin:0 auto;
    margin-top: 20px;
    margin-bottom: 10px;

  }
}
@media (max-width: 1120px) {
  .license_wrapper {
    max-width: 300px;
  }
  .feedback_form {

    .tn-atom__img {
      display: none;
    }
  }

}
@media (max-width: 500px) {
  .feedback_form {
  .feedBackFormText{
    font-size: 18px;
  }
}}
</style>
