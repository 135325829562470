<template>
  <div class="customer_relations">
    <div
        class="customer_title"
        data-aos="fade-right"
        data-aos-delay="1000">
      <span>
        Ми віддані
         <span style="color:#0388d1;font-weight: 300">
             своїм клієнтам
      </span>
      </span>


    </div>
    <div class="customer_text">
      Ми прагнемо до створення довгострокових взаємин з нашими клієнтами,
      які засновані на надійності та чесності.
      Наша мета - надання простих і зручних сервісів задоволення ваших потреб
    </div>
    <div class="btn_container">
      <router-link class="application-btn btn_green" to="/about">Дізнатись більше</router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.customer_relations{
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 5px;
  margin-top: 120px;
  margin-bottom: 60px;
}
.customer_title {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    font-size: 40px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.55;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    color: rgb(59, 59, 59);
    margin-bottom: 50px;
    align-self: start;
  }

  .s_part_title {
    color: #0388d1;
    font-size: 45px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.1;
    font-weight: 300;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
  }

  .customer_text {
    color: #000000;
    font-size: 25px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 300;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    vertical-align: middle;
    width: 100%;
    margin-bottom: 50px;
  }
.btn_container {
  display: flex;

  .application-btn {
    height: 40px;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: transparent;
    transition: all .5s;
    color: #3b3b3b;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    border:1px solid transparent;

  }

  .btn_green {
    background-color: #12b423;
    color: #FEFEFE;
  }

  .application-btn:hover {
    color: #09110a;
    background-color: #FEFEFE;
    border: 1px solid #12b423;
  }
}
@media (max-width: 700px) {
  .customer_relations{
    max-width: 1400px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 5px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .customer_title {
    font-size: 35px;
    margin-bottom: 30px;
    text-align: center;
  }

  .s_part_title {
    font-size: 30px;
    margin-top: 5px;
  }
  .customer_text {
    color: #000000;
    font-size: 20px;
    margin-bottom: 30px;
  }
}
</style>
