<script setup>

</script>

<template>
  <div style="width: 96%; margin-top: 100px;">
    <div class="alert_message_block">
      <p class="message_text title">
        Повідомлення про належність особи до захищеної категорії, як передбачено абзацом 3 підпункту 2 пункту 62 Розділу IV “Прикінцеві та перехідні положення” Закону України “Про споживче кредитування” разом з підтвердними документами приймаються за адресою:
      </p>
      <p class="message_text">
        Україна, 01033, м. Київ, вул. В. Васильківська, 39-А<br/>
        E-mail: <a href="mailto:info@sfactor.com.ua">info@sfactor.com.ua</a><br/>
        Телефони: +38 (044) 593-66-33, +38 (050) 380-05-83<br/>
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .alert_message_block {
    max-width: 1400px;
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #e9f5ff;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    box-shadow: 5px 5px 6px 0px rgb(0 0 0 / 10%);
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
    .message_text {
      color: #000000;
      font-size: 22px;
      font-family: "Montserrat", Arial, sans-serif;
      line-height: 1.55;
      font-weight: 300;
      border-color: transparent;
      background-position: center center;
      border-style: solid ;
      vertical-align: middle;
      width: 100%;
      text-align: center;
    }
    .title {
      font-weight: 600;
    }
  }
</style>