<template>
  <div class="mainContainer">

    <div data-aos="fade-right"
         data-aos-duration="1500"
         class="site_ordinary_text">
      Ми довіряємо клієнтам, яким відмовляють банки
    </div>
    <div class="good_inner-container" ></div>
    <div class="products_wrapper">
      <div class="product product_bg_blue" @click="cashClickHandler">
        <div class="product_title">
          Кошти в кредит
        </div>
        <div class="product_image">
          <img class="money_img"
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/photo.webp"
               imgfield="tn_img_1642082052838"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/photo.webp">
        </div>
        <div class="product_look">
          <a href="/cash">Детальніше</a>
          <img data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8----100_3.webp"
               imgfield="tn_img_1642067464107"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8----100_3.png">
        </div>
      </div>
      <div class="product product_bg_green"
           @click="productClickHandler"
      >
        <div class="product_title">
          Кредит на товар
        </div>
        <div class="product_image">
          <div class="copterStyle">
          <img
              data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/27870_Converted-0123.png"
              imgfield="tn_img_1642002115011"
              src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/27870_Converted-0123.webp">
          <img class=" t-img "
               data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/44.webp"
               imgfield="tn_img_1642002115020"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/44.webp">
          </div>
        </div>
        <div class="product_look">
          <a href="/good">Детальніше</a>
          <img data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8----100_3.webp"
               imgfield="tn_img_1642067464107"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/icons8----100_3.png">
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import router from "@/router";

export default {
  methods: {
    cashClickHandler() {
      router.push('/cash');
    },
    productClickHandler() {
      router.push('/good');
    },
  }
}
</script>

<style lang="scss" scoped>
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  max-width: 1400px;
  width: 95%;
  margin:0 auto;


  .products_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    height: 400px;

    .product {
      border-style: solid;
      box-shadow: 5px 5px 6px 0px rgb(0 0 0 / 20%);
      width: 100%;
      min-width: 320px;
      margin: 5px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      cursor: pointer;
      transition: all .2s;

      .product_title {
        color: #ffffff;
        font-size: 40px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 700;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        padding-left: 20px;
      }

      .product_image {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .copterStyle{
          position: relative;
          img {
            width: 250px;
          }
          .t-img{
            transform: rotate(-15deg);
            width: 120px;
            position: absolute;
            top:120px;
            right: 80px;
          }
        }

        .money_img {
          transition: all .5s;
          width: 60%;
        }

        img {
          width: 50%;
        }
      }

      .money_img:hover {
        -webkit-transform: rotate(360deg);
        transform: rotate(-10deg);
      }

      .product_look {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 30px;
        cursor: pointer;

        a {
          color: #ffffff;
          font-size: 16px;
          font-family: 'Montserrat', Arial, sans-serif;
          line-height: 1.55;
          font-weight: 400;
          background-position: center center;
          border-color: transparent;
          border-style: solid;
        }

        img {
          width: 30px;
        }
      }
    }

    .product_bg_blue {
      background: linear-gradient(45deg, #0294d7, #014265);
      border-radius: 15px;
      background-size: cover;
      border: 0px;
    }

    .product_bg_green {
      background: linear-gradient(45deg, #096713, #1fd232);
      border-radius: 15px;
      background-size: cover;
      border: 0px;
    }

    .product:hover {
      transform: scale(0.99);
    }
  }

  .customer_relations {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin-top: 120px;

    .customer_title {
      display: flex;
      flex-direction: row;
      font-weight: 700;
      font-size: 40px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.55;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      color: rgb(59, 59, 59);
      margin-bottom: 50px;
    }

    .s_part_title {
      color: #0388d1;
      font-size: 45px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.1;
      font-weight: 300;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
    }

    .customer_text {
      color: #000000;
      font-size: 25px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.55;
      font-weight: 300;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      vertical-align: middle;
      width: 100%;
      margin-bottom: 50px;
    }
  }
}



.bold_title {
  color: #000;
  font-size: 45px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 700;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 80px;
}

.site_ordinary_text {
  color: #000000;
  font-size: 20px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  text-align: center;
}

.site_ordinary_bold_text {
  color: #000000;
  font-size: 18px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.5;
  font-weight: 600;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
}

.application-btn {
  height: 40px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 10px;
  background-color: transparent;
  transition: all .5s;
  color: #3b3b3b;
  border: 1px solid #12b423;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.btn_green {
  background-color: #12b423;
  color: #FEFEFE;
}

.application-btn:hover {
  color: #FEFEFE;
  background-color: #12b423;
  border: 1px solid #12b423;
}
@media (max-width: 950px) {
  .mainContainer {
    .products_wrapper {
      flex-direction: column;
      margin: 300px 10px 200px 10px;
      align-items: center;
      display: flex;
      justify-content: center;

      .product:nth-child(2) {

        padding-bottom: 20px;

      }
    }
  }

}

@media (max-width: 630px) {
  .bold_title {
    font-size: 32px;
  }
  .mainContainer {
    .products_wrapper {
      flex-direction: column;
      margin: 70px 10px 70px 10px;
      align-items: center;

      .product {
        .product_image {
          display: none;
        }

        .product_title {
          font-size: 32px;
        }
      }
    }

    .customer_relations {
      margin-top: 50px;

      .customer_title span {
        font-size: 32px;

      }

      .customer_title span:nth-child(2) {
        font-size: 32px;
        margin-top: 2px;
      }
    }
  }
  .site_ordinary_text {
    font-size: 18px;
  }
  .frequent_questions-container {
    flex-direction: column;
    .question_block {
      font-size: 16px;
    }
  }
}

@media (max-width: 430px) {
  .mainContainer {
    .customer_relations {
      margin-top: 20px;

      .customer_title span {
        font-size: 19px;
      }

      .customer_title {
        margin-bottom: 10px;
      }

      .customer_title span:nth-child(2) {
        font-size: 19px;
        margin-bottom: 20px;
      }

      .customer_text {
        font-size: 16px;
      }
    }
  }
  .our_partners {
    margin-top: 40px;

    .partners_inner {
      .partners_title {
        font-size: 32px;
      }
    }
  }
}

</style>
