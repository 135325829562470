<template>
  <div class="our_partners">
    <div class="partners_inner">
      <div data-aos="zoom-in-up"
           data-aos-offset="300"
           class="partners_title">
        Наші партнери
      </div>
      <div class="img_wrapper">
<!--        <img class="tn-atom__img "-->
<!--             data-aos="fade-up"-->
<!--             data-aos-delay="1000"-->
<!--             data-original="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1280px-Eldoradoua_lo.png"-->
<!--             imgfield="tn_img_1482146723240"-->
<!--             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1280px-Eldoradoua_lo.webp">-->
        <img class="tn-atom__img"
             data-aos="fade-up"
             data-aos-delay="1200"
             data-original="https://static.tildacdn.com/tild3332-3663-4130-b866-303336303630/otp-logo.svg"
             imgfield="tn_img_1482146716807"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/otp-logo.svg">
        <img class="tn-atom__img"
             data-aos="fade-up"
             data-aos-delay="1400"
             data-original="https://static.tildacdn.com/tild3965-3263-4164-a462-353430303964/logo-white.svg"
             imgfield="tn_img_1482146710159"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/logo-white.svg">
        <img class="tn-atom__img"
             data-aos="fade-up"
             data-aos-delay="1600"
             data-original="https://static.tildacdn.com/tild3964-3766-4634-a436-653966313133/comfy-logo-new.png"
             imgfield="tn_img_1482146702066"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/comfy-logo-new.webp">
        <img class="tn-atom__img FS_style"
             data-aos="fade-up"
             data-aos-delay="1800"
             data-original="https://static.tildacdn.com/tild3364-3133-4339-b165-323532646631/F5_logo.svg"
             imgfield="tn_img_1482146611235"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/F5_logo.svg">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.our_partners {
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 60px;

  .partners_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .partners_title {
      color: #12b422;
      font-size: 45px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.55;
      font-weight: 700;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      padding-top: 100px;
    }

    .img_wrapper {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      align-items: center;
      flex-wrap: wrap;

      .tn-atom__img {
        max-width: 120px;
        height: 30px;
        margin: 20px;
      }

      .FS_style {
        max-height: 80px;
        height: 100%;
      }
    }
  }
}
@media (max-width: 450px) {
  .partners_title{
   padding-top: 40px;
    max-width: 420px;
    text-align: center;
    font-size: 35px;
  }
  .img_wrapper{
    flex-direction: column;
  }
}
</style>
