<template>
  <div class="benefits_wrapper">
<span class="benefits_title">Переваги</span>
    <div class="benefitsItems_container">
      <div
          class="benf_item"
          data-aos="zoom-in-left"
      >
        <img class="item_img"
             data-original="https://static.tildacdn.com/tild3966-3737-4332-a333-383938303834/22222.webp"
             imgfield="tn_img_1643638512854"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/22222.webp">
        <span class="item_title">Швидко</span>
        <span class="item_text">Приймаємо рішення за 5 хвилин</span>
      </div>
      <div
          class="benf_item"
          data-aos="zoom-in-up"
          data-aos-delay="500"
      >
        <img class="item_img"
             data-original="https://static.tildacdn.com/tild3439-3437-4662-a531-356661313062/01.png"
             imgfield="tn_img_1643636318273"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/01.webp">
        <span class="item_title">Легко</span>
        <span class="item_text">Лише паспорт, код та карта</span>
      </div>
      <div
          class="benf_item"
          data-aos="zoom-in-left"
          data-aos-delay="1000"
      >
        <img class="item_img"
             data-original="https://static.tildacdn.com/tild3364-3039-4437-a234-313331616431/45454545.png"
             imgfield="tn_img_1643639095207"
             src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/45454545.webp">
        <span class="item_title">Чесно</span>
        <span class="item_text">Показуємо всі витрати як є, без сюрпризів
</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.benefits_wrapper{
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;

  .benefits_title{
    color: #3b3b3b;
    font-size: 45px;
    font-family: 'Montserrat',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-bottom: 30px;
  }
  .benefitsItems_container{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    .benf_item{
      border-radius: 15px;
      background-color: #ffffff;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 20%);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      padding-top: 20px;
      padding-bottom: 20px;
      transition: all 0.5s;
      cursor: pointer;
    }
    .benf_item:hover {
      -webkit-transform: rotate(360deg);
      transform: rotate(-2deg);
    }

  }
}
.item_title{
  color: #1f1f1f;
  font-size: 25px;
  font-family: 'Montserrat',Arial,sans-serif;
  line-height: 1.55;
  font-weight: 600;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  text-align: center;
}
.item_img{
  max-width: 40%;
}
.item_text{
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat',Arial,sans-serif;
  line-height: 1.55;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  text-align: center;
}
@media (max-width: 800px) {
  .benefits_wrapper{
    .benefits_title{
      font-size: 40px;
    }
    .benefitsItems_container{
      flex-direction: column;
      align-items: center;
      .benf_item{
        margin-bottom: 10px;
        max-width: 70%;
        width:100%;

      }
}}}

</style>
