<template>
  <div class="contacts_wrapper">
    <div class="bold_title" style="float: left">
      Контакти
    </div>

    <div class="blocks_wrapper">
      <div class="contacts_block"
           data-aos="fade-up"
           data-aos-duration="1000"
           :style="{ 'background-color': item.bgColor }"
           v-for="item in contacts" :key="item">

        <div class="site_ordinary_bold_text">{{ item.b_title }}</div>
        <div class="site_ordinary_text" :style="{ 'font-size': item.b_title === 'Телефон' || 'Графік роботи'? '14px' : '18px' }" v-html="item.b_text"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      contacts: [
        {bgColor: '#FFF0E3', b_title: 'Головний офіс', b_text: 'Україна, 01033, м. Київ,<br/>вул. В. Васильківська, 39-А'},
        {bgColor: '#e9f5ff',
          b_title: 'Телефон',
          b_text: '<span style="font-weight: bold; font-size: 12px">Call center:</span><br/>+38 (044) 593-66-33 <br/> +38 (050) 380-05-83' +
              ' <br/> +38 (067) 536-64-83 <br/> +38 (093) 039-21-17<br/> ' +
              '<span style="font-weight: bold; font-size: 12px; line-height: 1px">Телефон для врегулювання простроченої заборгованості:</span><br/>' +
              ' +38 (044) 355 09 20\n'},
        {bgColor: '#f1ecfe', b_title: 'E-mail', b_text: 'info@sfactor.com.ua'},
        {
          bgColor: '#fef0f4',
          b_title: 'Графік роботи',
          b_text: 'Щоденно: 09:00 - 18:00 <br/> Вихідний: СБ-НД та святкові дні. <br/><strong>Call Center:</strong> 09:00 - 19:00 <br/>Вихідний: СБ-НД.<br/>' +
              '<strong>Відділ по роботі з проблемною заборгованістю:</strong> 09:00 - 18:00 <br/>Вихідний: СБ-НД та святкові дні.',
        },

      ],
    }
  }
}
</script>
<style lang="scss" scoped>
.contacts_wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  width: 95%;
  margin-top: 50px;
  margin: 0 auto;
  .blocks_wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    .contacts_block {
      max-width: 280px;
      min-height: 200px;
      width: 100%;
      border-radius: 20px;
      box-shadow: 5px 5px 6px 0px rgb(0 0 0 / 10%);
      margin-bottom: 10px;
      padding: 10px;
    }
  }
}
.site_ordinary_bold_text {
  color: #000000;
  font-size: 18px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.5;
  font-weight: 600;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
}
.site_ordinary_text {
  color: #000000;
  font-size: 20px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;

}
.bold_title {
  color: #000;
  font-size: 45px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 700;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 80px;
}

@media (max-width: 640px) {
  .contacts_wrapper {
    .blocks_wrapper {
      justify-content: center;

    }
  }
}
</style>
