<template>
  <div class="WeAreFaithful_wrapper">
    <span
        class="simple_title"
        data-aos="fade-up"
        data-aos-duration="1500"
    >
      Ми віддані своїм клієнтам
    </span>
    <span class="simple_under_title"
          data-aos="fade-up"
          data-aos-duration="2000">
      Ми прагнемо до створення довгострокових взаємин з нашими клієнтами,
      які засновані на надійності та чесності. Наша мета - надання простих
      і зручних сервісів вирішення ваших потреб.
    </span>
    <div class="simple_items_container">


      <div class="simple_element" style="background-color: #f1ecfe">

        <div class="simple_innerBlock">
          <span class="innerBlock_title"
                data-aos="fade-up"
                data-aos-duration="1000"
          >
              Клієнт
          </span>

          <img class="innerBlock_img"
               data-aos="zoom-in"
               data-original="https://static.tildacdn.com/tild6532-6361-4362-a361-376536373338/1-11.svg"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1-11.svg">

        </div>


        <span class="simple_InnerText"
              data-aos="fade-up"
              data-aos-duration="1000"
        >
         Наша клієнтоорієнтованість полягає у постійному вивченні
          потреб клієнтів та пошуку сучасних методів їх задоволення.
          Створюючи цінність, наші послуги забезпечують фінансування
          фізичних осіб.
        </span>
      </div>
      <div class="simple_element" style="background-color: #e9f5ff">

        <div class="simple_innerBlock">
          <span class="innerBlock_title"
                data-aos="fade-left"
                data-aos-duration="1000"

          >
            Сервіс
          </span>

          <img class="innerBlock_img"
               data-aos="zoom-in"
               data-original="https://static.tildacdn.com/tild3433-3435-4735-b531-333364393936/-09.svg"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/-09.svg">

        </div>


        <span class="simple_InnerText"
              data-aos="fade-up"
              data-aos-duration="1000"
        >
          Клієнтський сервіс, що ми пропонуємо,
          відповідає найвищим стандартам серед компаній
          фінансового сектору України. Наші сервіси допоможуть
          вам користуватись всіма кредитними програмами
          та службою клієнтської підтримки.
        </span>
      </div>
      <div class="simple_element" style="background-color: #fef0f4">

        <div class="simple_innerBlock">
          <span class="innerBlock_title"
                data-aos="fade-up"
                data-aos-duration="1000"
          >
            Iнновації
          </span>

          <img class="innerBlock_img"
               data-aos="zoom-in"
               data-original="https://static.tildacdn.com/tild3065-6364-4133-a637-376238303230/-10.svg"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/-10.svg">

        </div>


        <span
            class="simple_InnerText"
            data-aos="fade-up"
            data-aos-duration="1000"
        >
          Використання прогресивних технологій і програмного забезпечення дозволило
          нам запропонувати унікальні фінансові рішення для клієнтів Retail-ринку
          України. Ми здатні запропонувати фінансування навіть для клієнтів,
          яким банки відмовили у кредитуванні.
        </span>
      </div>
      <div class="simple_element" style="background-color: #fff0e3">

        <div class="simple_innerBlock">
          <span class="innerBlock_title"
                data-aos="fade-left"
                data-aos-duration="1000"
          >
            Захист
          </span>

          <img class="innerBlock_img"
               data-aos="zoom-in"
               data-original="https://static.tildacdn.com/tild3962-3839-4362-b861-323534376163/1-12.svg"
               alt="photo"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1-12.svg">

        </div>


        <span class="simple_InnerText"
              data-aos="fade-up"
              data-aos-duration="1000"
        >
          Використання передових технологій захисту інформації
          дозволяє нам бути впевненими в захисті прав користувача.
          З нами ви можете бути впевнені в збереженні
          персональних даних відповідно до чинного законодавства України.
        </span>
      </div>


    </div>

  </div>
</template>
<style lang="scss" scoped>
.WeAreFaithful_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;

  .simple_title {
    color: #12b423;
    font-size: 45px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.1;
    font-weight: 700;
    background-position: center center;
   border-color: transparent;
    border-style: solid;
    margin-bottom: 30px;
    align-self: center;
    text-align: center;

  }

  .simple_under_title {
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 300;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    max-width: 50%;
    text-align: center;
    margin-bottom: 30px;

  }
  .simple_items_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;

    .simple_element {
      border-radius: 15px;
      background-color: #ffffff;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      max-width: 40%;
      padding: 30px;
      min-width: 500px;
      margin-bottom: 60px;
      height: 260px;

      .simple_innerBlock {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 40px;

        .innerBlock_title {
          max-width: 50%;
          color: #12b422;
          font-size: 30px;
          font-family: 'Montserrat',Arial,sans-serif;
          line-height: 1.55;
          font-weight: 700;
          background-position: center center;
          border-color: transparent;
          border-style: solid;
        }

        .innerBlock_img {
          max-width: 120px;
        }
      }

      .simple_InnerText {
        color: #1f1f1f;
        font-size: 16px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 300;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
      }
    }
  }
}
@media (max-width: 720px) {
  .WeAreFaithful_wrapper{
    .simple_title{
      font-size: 40px;
      margin-bottom: 20px;
    }
    .simple_under_title{
      max-width: 70%;
    }
  }
}
@media (max-width: 650px) {
  .WeAreFaithful_wrapper {
    .simple_items_container {
      .simple_element {
        border-radius: 15px;
        background-color: #ffffff;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width: 40%;
        padding: 15px;
        min-width: 400px;
        margin-bottom: 40px;
        height: 200px;
        .simple_innerBlock{
          margin-bottom: 20px;
          .innerBlock_title{
            font-size: 20px;
          }
          .innerBlock_img{
            max-width: 80px;
          }
        }
        .simple_InnerText{
           font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 490px) {
  .WeAreFaithful_wrapper {
    .simple_title{
      font-size: 35px;
    }
    .simple_items_container {
      .simple_element {
        border-radius: 15px;
        background-color: #ffffff;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width: 40%;
        padding: 15px;
        min-width: 90%;
        margin-bottom: 40px;
        height: 320px;
        .simple_innerBlock{
          margin-bottom: 10px;
          flex-direction: column-reverse;
          align-items: center;
          .innerBlock_title{
            font-size: 30px;
          }
          .innerBlock_img{
            max-width: 100px;
          }
        }
        .simple_InnerText{
          font-size: 14px;
        }
      }
    }
  }
}
</style>
