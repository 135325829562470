<template>
  <div class="aboutPage_wrapper">
    <div class="about_page-container" style="background-color:#ebebeb">
      <div class="inner_title"
           data-aos="fade-up"
           data-aos-duration="1500">
      <span class="about_page_title">Сучасний Факторинг <span class="hyphen">—</span></span>
      <span class="about_page_title" style="font-weight: 100;color: rgb(3, 136, 209);margin-top: 5px">простий крок до мрії!</span>
      <p class="aboutPage_text">«Сучасний факторинг – компанія за допомогою своїх
        кредитних продуктів та партнерських сервісів в рітейлі, допомагає громадянам
        України задовольняти свої потреби. Компанія дозволяє клієнту,
        швидко і безпечно оформити кредит».
      </p>
      <a data-scroll href="#we" class="application-btn">Про нас</a>
      </div>
    </div>
    <div id="we" class="about_page-container">
      <WeAreFaithful />
    </div>
    <div class="about_page-container" style="background-color: black">
      <OurPartners/>
    </div>
    <div class="about_page-container">
      <HowDoWeWorking/>
    </div>
    <div class="about_page-container">
      <OurServices/>
    </div>
    <div class="about_page-container">
      <LawInformation/>
    </div>
    <div class="about_page-container" style="background-color: black">
      <CareerBlock/>
    </div>
    <div class="about_page-container" style="margin-top: 50px" >
      <ContactsBlock/>
    </div>

  </div>
</template>
<script>

import WeAreFaithful from './../components/common/WeAreFaithful/WeAreFaithful'
import OurPartners from './../components/common/OurPartners/OurPartners'
import HowDoWeWorking from './../components/common/HowDoWeWorking/HowDoWeWorking'
import OurServices from '../components/common/Services/OurServices'
import LawInformation from '../components/common/LawInformation/LawInformation'
import CareerBlock from '../components/common/Сareer/CareerBlock'
import ContactsBlock from '../components/common/contacts/ContactsBlock'
export default {

  name: 'about_page',
  components: {
    WeAreFaithful,
    OurPartners,
    HowDoWeWorking,
    OurServices,
    LawInformation,
    CareerBlock,
    ContactsBlock
  },

  mounted(){
    this.scrollHandler();
    document.title = 'Сучасний факторинг|Про нас ';
  },
  methods: {
    scrollHandler(){
      this.$emit('scrollTop');
    },
  },
  data() {
    return {}
  },

}
</script>
<style lang="scss" scoped>
.aboutPage_wrapper{
  display: flex;
  width: 100%;
  flex-direction: column;

  .about_page-container{
    display: flex;
    justify-content: center;
  }
}
.inner_title{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  justify-content: center;
  max-width: 1400px;
  width: 100%;
}
.about_page_title{
  color:rgb(18, 180, 35);
  font-weight: 700;
  font-size: 60px;
  font-family: 'Montserrat',Arial,sans-serif;
  line-height: 1.1;
  margin-top: 40px;
}
.aboutPage_text{
  color: #3b3b3b;
  font-size: 16px;
  font-family: 'Montserrat',Arial,sans-serif;
  line-height: 1.55;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  max-width: 60%;
  text-align: center;
}
.application-btn {
  height: 40px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 10px;
  background-color: transparent;
  transition: all .5s;
  color: #3b3b3b;
  border: 1px solid #12b423;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  font-family: Montserrat,sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn_green{
  background-color: #12b423;
  color: #FEFEFE;
}
.application-btn:hover {
  color: #FEFEFE;
  background-color: #12b423;
  border: 1px solid #12b423;
}
@media (max-width: 920px) {
  .about_page_title{
    font-size: 50px;
    margin-top: 20px;
  }
  .inner_title{
    height: 500px;
  }

}
@media (max-width: 720px) {
  .about_page_title{
    font-size: 40px;
    margin-top: 20px;
    .hyphen{
      display: none;
    }
  }
  .aboutPage_text{
    font-size: 14px;
  }
  .inner_title{
    height: 400px;
  }
}
@media (max-width: 550px) {
  .about_page_title{
    font-size: 35px;
    margin-top: 10px;

  }
  .aboutPage_text{
    font-size: 12px;
  }

  .about_page-container{

  }
}
@media (max-width: 390px) {
  .about_page_title{
    text-align: center;
  }
}
</style>
