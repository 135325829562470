<template>
  <div class="Career_wrapper">
    <div class="career_inner">
      <div
          class="title_block"
          data-aos="fade-up"
      >
        <span class="title_big">Кар'єра</span>
        <span class="title_small">Приєднуйтесь до команди професіоналів Сучасного Факторингу!</span>
      </div>
      <div
          class="logo_block"
          data-aos="fade-up"
      >
        <div class="logo_container">
          <img class="img_style"
               data-original="https://static.tildacdn.com/tild3535-6637-4633-a632-633033653065/work_light_bgs.png"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/work_light_bgs.png">
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.Career_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;
  .career_inner{
    display: flex;
    justify-content: space-around;
    width: 100%;
    .title_block{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      .title_big{
        color: #ffffff;
        font-size: 45px;
        font-family: 'Montserrat',Arial,sans-serif;
        line-height: 1.55;
        font-weight: 700;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
      }
      .title_small{
        color: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat',Arial,sans-serif;
        line-height: 1.55;
        font-weight: 100;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width: 350px;
      }
    }
    .logo_block{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .logo_container{
        border-width: 0px;
        border-radius: 10px;
        background-color: #ffffff;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        box-shadow: 2px 2px 13px 0px rgb(0 0 0 / 30%);
        height: 300px;
        width: 280px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .img_style{
          width: 200px;
          height: 70px;

        }
      }
    }
  }
}
@media (max-width: 900px) {
  .Career_wrapper {
    .career_inner{
    flex-direction: column;
      align-items: center;
    }
}
@media (max-width: 500px) {
  .Career_wrapper {
    .career_inner{
      flex-direction: column;
      align-items: center;
      .title_block{
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        text-align: center;
        .title_big{
          font-size: 35px;
        }
        .title_small{
          font-size: 14px;
        }
      }
      .logo_block{
        .logo_container{
          height: 250px;
          width: 230px;
        }
      }
    }
  }
}
}
</style>
