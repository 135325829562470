<template>
  <div class="calc_wrapper">

      <div
          class="block_name"
          data-aos="fade-up"
          data-aos-duration="1500"
      >
<!--        <div class="messageWrap" data-aos="fade-up"-->
<!--             data-aos-duration="1500"-->
<!--        >-->
<!--          <img class="attention_pic"-->
<!--               data-original="https://static.tildacdn.com/tild3235-6362-4439-b331-393533373330/platon-logo.png"-->
<!--               src="../../../assets/klipartz.com.png">-->
<!--          <div class="infoMessage">-->
<!--            Шановні клієнти. Наразі оформлення готівкових кредитів тимчасово неможливе.-->
<!--            Ви можете скористатися продуктом "Кредит на товар" в магазинах наших партнерів.-->
<!--          </div>-->
<!--        </div>-->
        <span class="text_style text_style_bold"
              style="color: rgb(18, 180, 34);">
            Кошти в кредит
          </span>
        <span class="text_style text_style_bold"
              style="color: #0388d1;">

            на будь-які потреби
          </span>
        <div class="block_name-btn">
          <span class="text_style"
                style="font-size: 22px;
                        font-weight: 300;
                         color: #3b3b3b;">Зручно, швидко та вигідно!</span>
          <div class="application-btn" @click="openCache">
            Оформити
          </div>
<!--          <div class="application-btn" @click="cashCounter(money_range, mounth_range)">-->
<!--            Оформити-->
<!--          </div>-->
        </div>
      </div>

      <div class="calc_block">
        <span class="text_style">Сума кредиту: <span style="font-weight: bold">{{ money_range }}</span></span>
        <span class="text_style">₴</span>
        <input v-model="money_range" name="N1" class="t-range "
               type="range" min="5000" max="50000"
               @change="cashCounter(money_range, mounth_range)"
               step="1000"/>
        <div style="display:flex;justify-content: space-between; max-width: 90%;width: 100%;margin-bottom:30px">
          <span class="text_style">5000</span>
          <span class="text_style">50000</span>
        </div>
        <span class="text_style" style="margin-bottom: 30px">Кількість місяців: <span
            style="font-weight: bold">{{ mounth_range }}</span></span>
        <input v-model="mounth_range" name="N1" class="t-range "
               type="range" min="6" max="18"
               @change="cashCounter(money_range, mounth_range)"
               step="6"/>

        <div style="display:flex;justify-content: space-between; max-width: 90%;width: 100%;margin-bottom:30px">
          <span class="text_style">6</span>
          <span class="text_style">18</span>
        </div>
        <span class="text_style">
          Щомісячний платіж, від: <span class="asterisk"> * </span>
        </span>
        <span class="text_style"
              style="font-weight: 600;font-size: 18px">₴{{ mouthlyPayment }}/міс.</span>

        <span class="text_style">
          <span class="add_info">
          <span class="asterisk">*</span>
              Наведена інформація не є кредитним калькулятором у розумінні постанови НБУ від 05.10.2021 No100.
              Щоб отримати доступ до відомостей про всі види споживчих кредитів, їх істотні характеристики та
              кредитний калькулятор, перейдіть до сторінки "Кредитні продукти".
          </span>
        </span>

      </div>
    </div>

</template>

<script>

export default {
  name: 'cashCalculator',
  components: {},
  methods: {
    cashCounter(money, mounthQuantity) {
      this.massageHandler();
      let generalCreditAmounth = Number(money / 100 * 10) + Number(money);
      let mouthlyComission = (generalCreditAmounth / 100 * 4.00);
      let mouthlyPayment = (generalCreditAmounth / mounthQuantity) + mouthlyComission;
      this.mouthlyPayment = Math.ceil(mouthlyPayment);
    },
    massageHandler() {
      this.infoMessageActive = true;
      const timer = setTimeout(()=> {
        this.infoMessageActive = false;
        clearTimeout(timer);
      },3000);
    },
    openCache() {
      window.open("https://online.sfactor.com.ua/join?ref=sfactor")
    }
  },
  data() {
    return {
      money_range: 5000,
      mounth_range: 6,
      mouthlyPayment: 0,
      infoMessageActive: false
    }
  },
  beforeMount() {
    this.massageHandler();
    this.cashCounter(this.money_range, this.mounth_range)
  }

}

</script>

<style lang="scss" scoped>
.application-btn {
  height: 40px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 10px;
  background-color: transparent;
  transition: all .5s;
  color: #3b3b3b;
  border: 1px solid #12b423;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.application-btn:hover {
  color: #FEFEFE;
  background-color: #12b423;
  border: 1px solid #12b423;
  cursor: pointer;
}

.text_style {
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  margin: 5px 10px 5px 10px;
}

.text_style_bold {
  font-weight: 700;
  font-size: 45px;
}

.calc_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;

  .block_name {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .messageWrap {
      border-radius: 15px;
      padding: 5px 10px 5px 10px;
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: white;
      box-shadow: 5px 5px 6px 0px rgb(0 0 0 / 10%);
      z-index:5;


      .infoMessage {
        color: #1f1f1f;
        font-size: 17px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 600;
        background-position: center center;
        text-align: center;

      }
      .attention_pic {
        width: 60px;
        height: 40px;
      }
    }

    .block_name-btn {
      display: flex;

    }
  }

  .calc_block {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .t-range {
      -webkit-appearance: none; /* Override default CSS styles */
      appearance: none;
      width: 90%; /* Full-width */
      height: 3px; /* Specified height */
      background: #d3d3d3; /* Grey background */
      outline: none; /* Remove outline */
      opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
      -webkit-transition: .2s; /* 0.2 seconds transition on hover */
      transition: opacity .2s;
    }

    .slider:hover {
      opacity: 1; /* Fully shown on mouse-over */
    }

    .t-range::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 15px; /* Set a specific slider handle width */
      height: 15px; /* Slider handle height */
      background: rgb(18, 180, 34);; /* Green background */
      cursor: pointer; /* Cursor on hover */
      border-radius: 50%;
    }

    .t-range::-moz-range-thumb {
      width: 5px; /* Set a specific slider handle width */
      height: 5px; /* Slider handle height */
      background: rgb(18, 180, 34);; /* Green background */
      cursor: pointer;
      border-radius: 50%;
    }

    .add_info {
      text-align: center;
      font-weight: 400;
      font-size: 10px;
      color: #bababa;
      line-height: 1;
      .asterisk {
        color: #ff0000;
        margin: 0 5px 0 5px;
      }
    }

  }

}

// media
@media (max-width: 1000px) {
  .calc_wrapper {
    flex-direction: column;
    margin: 60px 10px 60px 10px;
    align-items: center;

    .block_name {
      margin-top: 30px;
      margin-bottom: 50px;
      .messageWrap {
       top:220px;
        left:160px;
        width:400px;
        .infoMessage{
        font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .calc_wrapper {
    margin: 50px 10px 50px 10px;

    .block_name {
      align-items: center;
      .messageWrap {
        top:140px;
        left:10px;
        max-width:350px;
        background-color: white;
        z-index: 47;
        .infoMessage{
          font-size: 14px;

        }
        .attention_pic{
          display: none;
        }
      }


      .block_name-btn {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .text_style_bold {
    font-size: 32px;
    text-align: center;
    line-height: 1.1;
  }
}

@media (max-width: 420px) {
  .calc_wrapper {
    margin: 50px 10px 50px 10px;
  }
}
</style>
