<template>
  <div class="howTo_achieve-wrapper">
<span class="howTo_achieve-title">
  Як отримати?
</span>
<div class="howTo_achieve-block">

  <div
      class="achieve_element"
      data-aos="zoom-in-up"
      data-aos-delay="1000"
  >
    <div class="round_style">
      <span>1</span>
    </div>
    <span class="element_text">
      Заповнюйте анкету в кабінеті або з персональним помічником по телефону
    </span>
  </div>
  <div
      class="achieve_element"
      data-aos="zoom-in-up"
      data-aos-delay="2000"
  >
    <div class="round_style">
      <span>2</span>
    </div>
    <span class="element_text">
      Підпишіть договір
    </span>
  </div>
  <div
      class="achieve_element"
      data-aos="zoom-in-up"
      data-aos-delay="3000"
  >
    <div class="round_style">
      <span>3</span>
    </div>
    <span class="element_text">
      Отримайте кошти на карту
    </span>
  </div>

</div>
  </div>
</template>



<style lang="scss" scoped>
.howTo_achieve-wrapper{
  max-width: 1400px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;

  .howTo_achieve-title{
    color: #ffffff;
    font-size: 45px;
    font-family: 'Montserrat',Arial,sans-serif;
    line-height: 1.1;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
  }
  .howTo_achieve-block{
    display: flex;
    flex-direction: column;
    max-width: 40%;
    .achieve_element:nth-child(2){
    margin-left: 20px;
}
    .achieve_element{
      display: flex;
      margin-bottom: 30px;
      align-items: center;

      .element_text{
        color: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat',Arial,sans-serif;
        line-height: 1.55;
        font-weight: 300;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width:60%;
        margin-left: 20px;

      }
      .round_style{
        width: 70px;
        height: 70px;
        border-radius: 50%;
        position: relative;
        background-color: #0388d1;
        span{
          position: absolute;
          top:20%;
          left:40%;
          color: #ffffff;
          font-size: 25px;
          font-family: 'Montserrat',Arial,sans-serif;
          line-height: 1.55;
          font-weight: 300;
          background-position: center center;
          border-color: transparent;
          border-style: solid;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .howTo_achieve-wrapper{
    flex-direction: column;
    align-items: center;
    .howTo_achieve-title{
      font-size: 40px;
      margin-bottom: 20px;
    }
    .howTo_achieve-block{
      max-width: 90%;
      align-items: start;
      .achieve_element:nth-child(2){
        margin-left: 0;
      }
      .achieve_element{
        .element_text{
          max-width: 200px;
        }
      }
    }
  }
}
</style>
