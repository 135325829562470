<template>
<div class="headerWrapper">
  <div class="headerInner">
    <div class="logoWrapper" @click="onLogoClick">
      <img src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/logo_-___-01 (1).svg" alt="picture"/>
    </div>
    <div class="navWrapper">
      <ProjectNavigation/>
    </div>
    <div class="phonesBlock">
      <a class="header_phone" href="tel:38 044 593 66 33">38 044 593 66 33</a>
      <span class="phone_text">Служба турботи про клієнтів</span>
    </div>

    <div class="media_container">
      <div style="max-width: 40px;margin: 5px 5px"
           @click="onClickHandler('https://t.me/sfactor_bot')"
      >
        <img
            src="@/assets/media-logos/telegram.png"
            alt="telegram"
            style="cursor: pointer; max-width: 1.5rem"
        />
      </div>
      <div style="max-width: 40px;margin: 5px 5px"
           @click="onClickHandler('viber://pa?chatURI=sfactor_bot')"
      >
        <img
            src="@/assets/media-logos/viber.png"
            alt="viber"
            style="cursor: pointer; max-width: 1.5rem"
        />
      </div>
    </div>

    <div style="display: flex" >
      <a @click="linkRedirector(linkToCab)" style="cursor: pointer" class="application-btn">Особистий кабінет</a>
<!--      <a href="https://easypay.ua/catalog/bank/pogashenie-kredita/modern-factoring-sub/modern-factoring-credit?" class="application-btn btn_green">Сплатити EasyPay</a>-->
      <a href="https://devplaton.com.ua/invoices/sfactor_client/" class="application-btn btn_green">Оплата PSP Platon (Visa, MasterCard)</a>
<!--      <a href="https://city24.ua/ua/pogashenie-kredita/credit-unions/suchasniy-faktoring" class="application-btn btn_green">Сплатити City24</a>-->
    </div>
    <div class="menu_btn application-btn" @click="onMenuBtnHandler">
      Меню
      <Menu style="padding-left: 10px; padding-top: 4px; cursor: pointer" />
    </div>
  </div>

</div>
</template>

<script>
import ProjectNavigation from './headerNav'
import Menu from "vue-material-design-icons/Menu.vue";
import router from "@/router";

export default {
  name: 'HeaderMain',
  components: {
    ProjectNavigation, Menu
  },
  methods: {
    onLogoClick() {
      router.push('/');
    },
    linkRedirector(link){
      window.location.href = link
    },
    onMenuBtnHandler(){
      if(this.$route.path === "/menu"){
        this.$router.go(-1)
      }
      router.push("/menu");
    },
    onClickHandler(link) {
      window.open(link, '_blank');
    }
  },
  data() {
    return {
      linkToCab: "https://my.sfactor.com.ua/",
      dropDownToggle: true
    }
  }
}
</script>

<style lang="scss" scoped>


.headerWrapper{
  display: flex;
  background-color:  #ebebeb;
  height: 90px;
  width: 100%;
  justify-content: center;
  .headerInner{
    max-width: 3000px;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
  }
}
a{
  text-decoration: none;
}
.logoWrapper{
  width: 150px;
  margin: 5px;
  cursor: pointer;
}
.supportContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.phonesBlock{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .header_phone{
    font-family: Montserrat,sans-serif;
    color: rgb(59, 59, 59);
    font-weight: 600;
  }
  .phone_text{
    font-size: 10px;
    font-family: Montserrat,sans-serif;
  }
}
.headerInner{
 .menu_btn{
   display: none;
 }
}
.application-btn {
  height: 40px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 10px;
  background-color: transparent;
  transition: all .5s;
  color: #3b3b3b;
  border: 1px solid #12b423;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn_green{
  background-color: #12b423;
  color: #FEFEFE;
}
.application-btn {
  text-align: center;
}
.application-btn:hover {
  color: #FEFEFE;
  background-color: #12b423;
  border: 1px solid #12b423;
}
.navWrapper{
  float: right;
}

@media (max-width: 1250px) {
  .headerInner {
    .navWrapper{
      display: none;
    }
    a{
      display: none;
    }
  }
  .headerInner {
    .menu_btn{
      display: flex;
      align-content: center;

    }

  }
.phonesBlock{
  display: none;
}
  .media_container{
    display: none;
  }
}

</style>
