<template>
  <div class="requirements_wrapper">
    <div class="reqImages">
      <img data-original="https://static.tildacdn.com/tild3864-3034-4931-b231-623636393266/0-13.png"
           imgfield="tn_img_1642075342098"
           src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/0-13.webp">
      <img class="t-img1 t-img"
           data-original="https://static.tildacdn.com/tild3064-6433-4265-b764-383965346231/4.gif"
           imgfield="tn_img_1642075342123"
           src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/4.gif">
      <img class="t-img2 t-img"
           data-original="https://static.tildacdn.com/tild3436-3032-4330-a366-626161303063/0-14.png"
           imgfield="tn_img_1642075342113"
           src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/0-14.png">
    </div>
    <div class="reqDescription">
      <span class="descriptionTitle"
            style="font-weight: 700;"
            data-aos="fade-up"
            data-aos-duration="1500"
      >Що потрібно
      </span>
      <span
          class="descriptionTitle"
          style="color: rgb(3, 136, 209);"
          data-aos="fade-up"
          data-aos-duration="2500">
        для отримання кредиту
      </span>
      <div class="descriptionItems_Wrapper">
        <div class="descriptionItem"
             data-aos="flip-left"
             data-aos-easing="ease-out-cubic"
        >
          <div class="number_element">
            <span>01</span>
          </div>
          <span class="desc_title">Мобільний телефон</span>
        </div>
        <div class="descriptionItem"
             data-aos="flip-left"
             data-aos-easing="ease-out-cubic"
             data-aos-delay="500"
        >
          <div class="number_element">
            <span>02</span>
          </div>
          <span class="desc_title">Вік від 18 років</span>
        </div>
        <div class="descriptionItem"
             data-aos="flip-left"
             data-aos-easing="ease-out-cubic"
             data-aos-delay="1000"
        >
          <div class="number_element">
            <span>03</span></div>
          <span class="desc_title">Банківська карта</span>
        </div>
        <div class="descriptionItem"
             data-aos="flip-left"
             data-aos-easing="ease-out-cubic"
             data-aos-delay="1500"
        >
          <div class="number_element">
            <span>04</span></div>
          <span class="desc_title">Паспорт та ІПН</span>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'credit_requirements',
  components: {},
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.requirements_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;

  .reqImages {
    display: flex;
    max-width: 400px;
    display: flex;
    position: relative;

    .t-img {
      max-width: 90px;
      position: absolute;
      bottom: 0;
      right: 220px;
    }

    .t-img1 {
      bottom: 0;
      right: 50%;
    }

    .t-img2 {
      max-width: 70px;
      bottom: 15%;
      right: 10%;
    }

    img {
      max-width: 450px;

    }
  }

  .reqDescription {
    display: flex;
    flex-direction: column;
    max-width: 48%;

    .descriptionTitle {
      font-size: 45px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.1;
      font-weight: 300;
      margin-left: 10px;
    }

    .descriptionItems_Wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 30px;

      .descriptionItem {
        display: flex;
        justify-content: space-around;
        max-width: 49%;
        width: 100%;

        .desc_title {
          color: #000000;
          font-size: 22px;
          font-family: 'Montserrat', Arial, sans-serif;
          line-height: 1.3;
          font-weight: 300;
          background-position: center center;
          border-color: transparent;
          border-style: solid;
          margin: 10px;
          max-width: 50%;
        }

        .number_element {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background-color: #0388d1;
          position: relative;
          margin: 10px;

          span {
            position: absolute;
            top: 25%;
            right: 25%;
            color: #ffffff;
            font-size: 16px;
            font-family: 'Montserrat', Arial, sans-serif;
            line-height: 1.55;
            font-weight: 300;
            background-position: center center;
            border-color: transparent;
            border-style: solid;

          }
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .requirements_wrapper {
    flex-direction: column;
    align-items: center;

    .reqImages {
    }

    .reqDescription {
      text-align: center;
    }
  }
}

@media (max-width: 850px) {
  .requirements_wrapper {
    .reqImages {
    }

    .reqDescription {
      max-width: 100%;

      .descriptionTitle {
        font-size: 35px;
      }

      .descriptionItems_Wrapper {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .descriptionItem {
          max-width: 90%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .requirements_wrapper {
    .reqImages {
      max-width: 350px;

      img {
        max-width: 350px;

      }
    }
  }
}

</style>
