<script>
export default {
  name: 'BannerHeader',
  data() {
    return {
      bannerImage: require("@/assets/banner/image_123650291-2.jpg")
    }
  }
}
</script>

<template>
  <div class="banner-container">
    <img :src="bannerImage" alt="Black friday!" class="banner-image">
    <div class="overlay">
      <div class="overlay-text">
        <p class="text-row">&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner-container {
  position: relative;
  width: 100%;
  background: #060A0B;

  .banner-image {
    width: 100%;
    max-height: 60px;
    object-fit: contain;
    opacity: 0.7;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overlay-text {
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    font-family: "Montserrat", Arial, sans-serif;
    text-shadow: 0 0 8px #004f00;
    padding: 0 4px 0 4px;

    .text-row {
      width: 100%;
      text-align: center;
      line-height: 1.2em;
    }
  }

  @media (max-width: 602px) {
    .banner-image {
      height: 150px;
      object-fit: cover;
    }

    .overlay {
      height: 150px;
    }

    .overlay-text {
      font-size: 1.0em;
    }
  }
}
</style>
