<template>
  <div class="feedbacks_container">
    <div class="bold_title">
      Відгуки
    </div>
    <div style="width: 100%">
      <swiper
          style="height: 500px;width: 80%"
          :modules="modules"
          :slides-per-view="1"
          :space-between="50"
          :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
          :pagination="{ clickable: true }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          loop="true"
          grabCursor="true"
      >
        <swiper-slide v-for="n in feedback_items" :key="n">
          <div style="display: flex;flex-direction: column; align-items: center">
            <div style="margin-bottom: 40px">

              <img v-if="n.f_photo !== ''" style="max-width: 200px;border-radius: 50%;" :src="n.f_photo" alt="user-photo"/>
              <img v-else style="max-width: 160px" src="../../../assets/factoring-logo.png" alt="user-NoPhoto"/>
            </div>
            <div class="site_ordinary_text" style="text-align: center;margin-bottom: 20px; max-width: 90%">
              {{ n.f_text }}
            </div>
            <div style="font-size: 20px;font-weight: 600;font-family: 'Montserrat',Arial,sans-serif;">{{
                n.f_name
              }}
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </swiper>
    </div>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import AOS from "aos";
import {A11y, Navigation} from "swiper";

AOS.init();

export default {
  name: 'FeedBack',
  components: {
    SwiperSlide,
    Swiper

  },
  data() {
    return {
      feedback_items: [
        {
          f_text: 'Хочу подякувати менеджеру, який консультував мене! За розуміння,' +
              ' дбайливе ставлення, за пошук найкращих пропозицій у такий важкий час!' +
              ' Дякую, Ви найкращі!',
          f_name: 'Юлія',
          f_photo: ""
        }, {
          f_text: 'Щиро дякую. За допомогу у вирішенні писання з заборгованістю в цей складний час.',
          f_name: 'Марина',
          f_photo: ""
        },
        {
          f_text: 'Мав фінансові проблеми з виплатою кредиту. Компанія дуже виручила,' +
              ' запропонувавши реструктуризацію боргу. Завдяки цьому зміг розрахуватися' +
              ' з кредитом. Дякую Вам за розуміння клієнтів.',
          f_name: 'Сергій',
          f_photo: ""
        },
        {
          f_text: 'Спасибо что быстро выручили в сложной ситуации. Успехов!',
          f_name: 'Ирина',
          f_photo: ""
        },
        {
          f_text: 'Дякую за допомогу . Радує, що оперативно, ввічливо, чесно. Хай вам щастить.',
          f_name: 'Татьяна',
          f_photo: ""
        },
        {
          f_text: 'Считаю что мне с этой компанией очень повезло. Крутые ребята, на горячей линии помогли решить все вопросы с первого раза.',
          f_name: 'Ирина',
          f_photo: ""
        },
        {
          f_text: 'Хороший сервіс, якісні послуги. Дуже виручають. Оплачувати можна навіть серед ночі.',
          f_name: 'Лілія ',
          f_photo: ""
        },
          {
        f_text: 'Я дуже радий. Виграв айфон 11 в компанії Сучасний Факторинг.' +
            ' Я сам не зміг приїхати в Київ для того, щоб його отримати, тому мені привіз його кур\'єр.' +
            ' Дуже вам дякую. Брав вже тут 4 кредити.' +
            'Олександр',
        f_name: 'Олександр',
        f_photo: ""
      },
        {
          f_text: 'Дуже лояльні та оперативні, а також ввічливі співробітники, роботою дуже задоволена.',
          f_name: 'Анна',
          f_photo: ""
        },
        {
          f_text: 'Очень расстроился, когда отказал банк, хотел оформить в мфо, но там бешенный процент.' +
              ' Позвонили сотрудники из Современного Факторинга, предложили процент практически банковский. Доволен.',
          f_name: 'Максим',
          f_photo: ""
        },
      ],
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      swiper
    };
    const onSlideChange = () => {

    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, A11y],
    };
  },
}
</script>
<style lang="scss" scoped>
.feedbacks_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 1400px;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;
  height: 100%;
}
.bold_title {
  color: #000;
  font-size: 45px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 700;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 80px;
}

.site_ordinary_text {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  text-align: center;
  margin-bottom: 100px;
  z-index: 20;
  height: 100%;
}
.swiper-button-prev, .swiper-button-next {
  color: #12b423;
}
@media (max-width: 500px) {
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
}
</style>
