<template>
  <div class="pay_simple_wrapper">
    <span class="simple_title">
      Зручно сплачувати кредит
    </span>
    <span class="simple_under_title">
      Ми зробили все, щоб вам було просто і зручно сплачувати кредит.
      До ваших послуг найкращі технології від провідних фінансових партнерів
    </span>
    <div class="simple_items_container">

      <div class="simple_element"
          data-aos="zoom-in-up"
          data-aos-delay="200"
      >

        <div class="simple_innerBlock">
          <span class="innerBlock_title" >
            Термінали самообслуговування
          </span>

            <img class="innerBlock_img"
                 data-original="https://static.tildacdn.com/tild6533-3563-4334-b466-663761376234/1-02.svg"
                 alt="picture"
                 src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1-02.svg">

        </div>


        <span class="simple_InnerText">
          Ви маєте можливість внести оплату за кредит в будь-якому платіжному терміналі одного з наших партнерів
        </span>
      </div>
      <div class="simple_element"
           data-aos="zoom-in-up"
           data-aos-delay="400"
      >

        <div class="simple_innerBlock">
          <span class="innerBlock_title" >
            Платіжною банківською карткою
          </span>

          <img class="innerBlock_img"
               data-original="https://static.tildacdn.com/tild3531-6230-4064-b963-333965653264/2-03.svg"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/2-03.svg">

        </div>


        <span class="simple_InnerText">
         Сплачуйте сидячи на улюбленому дивані онлайн. Здійсніть оплату за допомогою вашої банківської карти
        </span>
      </div>
      <div class="simple_element"
           data-aos="zoom-in-up"
           data-aos-delay="600"
      >

        <div class="simple_innerBlock">
          <span class="innerBlock_title" >
            У відділенні банку
          </span>

          <img class="innerBlock_img"
               data-original="https://static.tildacdn.com/tild3834-6338-4131-b564-636630663133/3-04.svg"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/3-04.svg">

        </div>


        <span class="simple_InnerText">
          Сплатіть в касі будь-якого банку України.
          Перерахуйте на наші реквізити суму не меншу за щомісячний платіж
        </span>
      </div>
      <div class="simple_element"
           data-aos="zoom-in-up"
           data-aos-delay="800"
      >

        <div class="simple_innerBlock">
          <span class="innerBlock_title">
            Через особистий кабінет
          </span>

          <img class="innerBlock_img"
               data-original="https://static.tildacdn.com/tild3063-6133-4434-b734-663764373366/erer.png"
               alt="picture"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/erer.webp">

        </div>


        <span class="simple_InnerText">
          Внесіть оплату, увійшовши до особистого кабінету.
          Чат-бот зробить процес оплати простим та зрозумілим
        </span>
      </div>


    </div>

  </div>
</template>
<style lang="scss" scoped>
.pay_simple_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;

  .simple_title {
    color: #ffffff;
    font-size: 45px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.1;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-bottom: 30px;
    text-align: center;
  }

  .simple_under_title {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 300;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    max-width: 60%;
    text-align: center;
    margin-bottom: 30px;
  }
  .simple_items_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    .simple_element {
      border-radius: 15px;
      background-color: #ffffff;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      max-width: 40%;
      padding: 30px;
      min-width: 500px;
      margin-bottom: 40px;
      height: 200px;

      .simple_innerBlock {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 40px;

        .innerBlock_title {
          color: #12b423;
          font-size: 20px;
          font-family: 'Montserrat', Arial, sans-serif;
          line-height: 1.55;
          font-weight: 700;
          background-position: center center;
          border-color: transparent;
          border-style: solid;
          max-width: 50%;
        }

        .innerBlock_img {
         max-width: 120px;
        }
      }

      .simple_InnerText {
        color: #1f1f1f;
        font-size: 16px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 300;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        text-align: center;
      }
    }
  }
}
@media (max-width: 720px) {
  .pay_simple_wrapper{
    .simple_title{
      font-size: 40px;
      margin-bottom: 20px;
    }
    .simple_under_title{
      max-width: 70%;
    }
  }
}
@media (max-width: 650px) {
  .pay_simple_wrapper {
    .simple_items_container {
      .simple_element {
        border-radius: 15px;
        background-color: #ffffff;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width: 40%;
        padding: 15px;
        min-width: 400px;
        margin-bottom: 40px;
        height: 200px;
        .simple_innerBlock{
          margin-bottom: 20px;
          .innerBlock_title{
            font-size: 20px;
            max-width: 90%;
          }
          .innerBlock_img{
            max-width: 80px;
          }
        }
        .simple_InnerText{
          font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 490px) {
  .pay_simple_wrapper {
    .simple_title{
      font-size: 35px;
    }
    .simple_items_container {
      .simple_element {
        border-radius: 15px;
        background-color: #ffffff;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width: 40%;
        padding: 15px;
        min-width: 90%;
        margin-bottom: 40px;
        height: 320px;
        .simple_innerBlock{
          margin-bottom: 10px;
          flex-direction: column-reverse;
          align-items: center;
          justify-content: space-around;
          .innerBlock_title{
            font-size: 25px;
            text-align: center;
          }
          .innerBlock_img{
            max-width: 120px;
          }
        }
        .simple_InnerText{
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}

</style>
