<template>
  <div class="menuWrapper">
    <div class="menu_inner">
      <span class="policeTitle">Політика обробки персональних даних</span>
      <ul class="rules_container">
        <li class="main_li">
          Загальні положення. Дана політика обробки персональних даних складена
          відповідно до вимог закону «Про персональні дані» та визначає порядок
          обробки персональних даних та заходи щодо забезпечення безпеки персональних даних,
          що вживаються ТОВ «Сучасний факторинг» (далі - Оператор).
          <p class="secondaryLi">Оператор ставить за свою найважливішу мету та умову здійснення
            своєї діяльності дотримання прав і свобод людини і громадянина під час обробки
            персональних даних, в тому числі захисту прав недоторканності
            приватного життя, особисту і сімейну таємницю.
          </p>
          <p class="secondaryLi">Дана політика Оператора щодо обробки персональних даних
            (далі - Політика) застосовується до всієї інформації, яку Оператор може
            отримати про відвідувачів веб-сайту <span
                style="font-weight: 700">http://officialfactoring.tilda.ws/.</span>
          </p>
        </li>
        <li class="main_li">Основні поняття, що використовуються в Політиці
          <p class="secondaryLi">Автоматизована обробка персональних даних − обробка персональних даних за допомогою
            засобів обчислювальної техніки;</p>
          <p class="secondaryLi">Блокування персональних даних − тимчасове припинення обробки персональних даних (за
            винятком випадків, коли обробка необхідна для уточнення персональних даних);</p>
          <p class="secondaryLi">Веб-сайт − сукупність графічних та інформаційних матеріалів, а також програм для ЕОМ і
            баз даних, що забезпечують їх доступність в мережі інтернет за мережевою адресою <span
                style="font-weight: 700">http://officialfactoring.tilda.ws/;</span></p>
          <p class="secondaryLi">Інформаційна система персональних даних − сукупність персональних даних, що містяться в
            базах даних, та інформаційних технологій і технічних засобів, що забезпечують їх обробку;</p>
          <p class="secondaryLi">Знеособлення персональних даних − дії, в результаті яких неможливо визначити без
            використання додаткової інформації, приналежність персональних даних конкретному Користувачеві чи іншому
            суб'єкту персональних даних;</p>
          <p class="secondaryLi">Обробка персональних даних − будь-яка дія (операція) або сукупність дій (операцій), що
            здійснюються з використанням засобів автоматизації або без використання таких засобів з персональними
            даними, включаючи збір, запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг,
            використання, передачу (поширення, надання, доступ), знеособлення, блокування, видалення, знищення
            персональних даних;</p>
          <p class="secondaryLi">Оператор − державний орган, муніципальний орган, юридична чи фізична особа, яка
            самостійно або спільно з іншими особами організовують і (або) здійснюють обробку персональних даних, а також
            визначають цілі обробки персональних даних, склад персональних даних, що підлягають обробці, дії (операції),
            що здійснюються з персональними даними;</p>
          <p class="secondaryLi">Персональні дані − будь-яка інформація, що прямо або побічно відноситься до певного
            Користувача веб-сайту <span style="font-weight: 700">http://officialfactoring.tilda.ws/;</span></p>
          <p class="secondaryLi">Користувач − будь-який відвідувач веб-сайту <span style="font-weight: 700">http://officialfactoring.tilda.ws/;</span>
          </p>
          <p class="secondaryLi">Надання персональних даних − дії, спрямовані на розкриття персональних даних певній
            особі або певному колу осіб;</p>
          <p class="secondaryLi">Поширення персональних даних − будь-які дії, спрямовані на розкриття персональних даних
            невизначеному колу осіб (передача персональних даних) або на ознайомлення з персональними даними
            необмеженого кола осіб, в тому числі оприлюднення персональних даних в засобах масової інформації,
            розміщення в інформаційно-телекомунікаційних мережах або надання доступу до персональних даних будь-яким
            іншим способом;</p>
          <p class="secondaryLi">Транскордонна передача персональних даних − передача персональних даних на територію
            іноземної держави, органу влади іноземної держави, іноземній фізичній або іноземній юридичній особі;</p>
          <p class="secondaryLi">Знищення персональних даних − будь-які дії, в результаті яких персональні дані
            знищуються безповоротно з неможливістю подальшого відновлення змісту персональних даних в інформаційній
            системі, або знищення матеріальних носіїв персональних даних.</p>
        </li>
        <li class="main_li">
          Оператор може обробляти такі персональні дані Користувача:
          <p class="secondaryLi">Прізвище, ім'я, по батькові;</p>
          <p class="secondaryLi">Електронна адреса;</p>
          <p class="secondaryLi">Номери телефонів;</p>
          <p class="secondaryLi">Також на сайті відбувається збір і обробка знеособлених даних про відвідувачів (в т. ч.
            файлів «cookie») за допомогою сервісів інтернет-статистики.</p>
          <p class="secondaryLi">Перераховані вище дані далі по тексту Політики об'єднані загальним поняттям Персональні
            дані.</p>
        </li>

        <li class="main_li">Мета обробки персональних даних
          <p class="secondaryLi">Оператор може обробляти такі персональні дані Користувача:</p>
          <p class="secondaryLi">Також Оператор має право направляти Користувачеві повідомлення про нові продукти та
            послуги, спеціальні пропозиції та різні події. Користувач завжди може відмовитися від отримання
            інформаційних повідомлень, направивши Оператору лист на адресу електронної пошти info@sfactor.com.ua з
            позначкою «Відмова від повідомлень про нові продукти та послуги, спеціальні пропозиції».Також Оператор має
            право направляти Користувачеві повідомлення про нові продукти та послуги, спеціальні пропозиції та різні
            події. Користувач завжди може відмовитися від отримання інформаційних повідомлень, направивши Оператору лист
            на адресу електронної пошти info@sfactor.com.ua з позначкою «Відмова від повідомлень про нові продукти та
            послуги, спеціальні пропозиції».</p>
          <p class="secondaryLi">Знеособлені дані користувачів, що збираються за допомогою сервісів інтернет-статистики,
            служать для збору інформації про дії користувачів на сайті, поліпшення якості сайту та його змісту.</p>
        </li>
        <li class="main_li">
          Правові підстави обробки персональних даних

          <p class="secondaryLi">
            Оператор обробляє персональні дані Користувача тільки в разі
            їх заповнення та/або відправки Користувачем самостійно через спеціальні
            форми, розташовані на сайті <span style="font-weight: 700">http://officialfactoring.tilda.ws/.</span>
            Заповнюючи відповідні форми і/або відправляючи свої персональні дані Оператору,
            Користувач висловлює свою згоду з цією політикою.
          </p>
          <p class="secondaryLi">
            Оператор обробляє знеособлені дані про Користувача у випадку,
            якщо це дозволено в налаштуваннях браузера Користувача (включено
            збереження файлів «cookie» і використання технології JavaScript).
          </p>
        </li>
        <li class="main_li">
          Порядок збору, зберігання, передачі та інших видів обробки персональних даних,
          безпека персональних даних, які обробляються Оператором, забезпечується шляхом
          реалізації правових, організаційних і технічних заходів, необхідних для виконання
          в повному обсязі вимог чинного законодавства у сфері захисту персональних даних.
          <p class="secondaryLi">
            Оператор забезпечує збереження персональних даних і вживає всіх можливих заходів,
            що виключають доступ до персональних даних неуповноважених осіб.
          </p>
          <p class="secondaryLi">
            Персональні дані Користувача ніколи, ні за яких умов не будуть передані третім особам,
            за винятком випадків, пов'язаних з виконанням чинного законодавства.
          </p>
        </li>
        <li class="main_li">
          У разі виявлення неточностей в персональних даних, Користувач може актуалізувати
          їх самостійно, шляхом подання відповідного повідомлення Оператору
          на адресу електронної пошти info@sfactor.com.ua з позначкою «Актуалізація персональних даних».
        </li>
        <li class="main_li">
          Термін обробки персональних даних є необмеженим. Користувач може в будь-який момент
          відкликати свою згоду на обробку персональних даних, направивши Оператору
          повідомлення на електронну пошту Оператора за адресою <span
            style="font-weight: 700">info@sfactor.com.ua</span> з
          позначкою «Відкликання згоди на обробку персональних даних».
        </li>
        <li class="main_li">
          Транскордонна передача персональних даних
          <p class="secondaryLi">
            Оператор до початку здійснення транскордонної передачі
            персональних даних зобов'язаний переконатися в тому, що
            іноземною державою, на територію якої передбачається здійснення
            передачі персональних даних, забезпечується надійний захист
            прав суб'єктів персональних даних.
          </p>
          <p class="secondaryLi">
            Транскордонна передача персональних даних на території іноземних держав,
            які не відповідають зазначеним вище вимогам, може здійснюватися
            лише в разі наявності згоди суб'єкта персональних даних на транскордонну
            передачу його персональних даних в письмовій формі та/або
            виконання договору, стороною якого є суб'єкт персональних даних.
          </p>
        </li>
        <li class="main_li">
          Прикінцеві положення

        </li>
        <li class="main_li">
          Користувач може отримати будь-які роз'яснення з питань,
          що стосуються обробки його персональних даних, звернувшись
          до Оператора за допомогою електронної пошти <span style="font-weight: 700">info@sfactor.com.ua.</span>
        </li>
        <li class="main_li">
          В цьому документі будуть відображені будь-які
          зміни політики обробки персональних даних
          Оператором. Політика діє безстроково до заміни її новою версією.
        </li>
        <li class="main_li">
          Актуальна версія Політики у вільному доступі розташована
          в мережі Інтернет за адресою <span style="font-weight: 700">http://officialfactoring.tilda.ws/police.
       </span>
        </li>
     <div @click="$router.push('/')" class="application-btn btn_toMain">Головна</div>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  name: 'policePage',
  components: {},
  mounted() {
    this.$emit('scrollTop');
  },
  methods: {},
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.menuWrapper {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #efefe8;
  display: flex;
  justify-content: center;

  .menu_inner {
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;


    .rules_container {
      max-width: 80%;
      margin-top: 50px;
      align-self: center;
    }

    .policeTitle {
      font-size: 60px;
      font-family: 'Montserrat', Arial, sans-serif;
      color: #12b422;
      font-weight: 700;
      max-width: 60%;
      text-align: center;
      align-self: center;
      margin: 50px 10px 0 10px;
    }

    .main_li {
      font-family: 'Montserrat', Arial, sans-serif;
      font-weight: 300;
      color: #000;
      font-size: 16px;

      .secondaryLi {
        font-family: 'Montserrat', Arial, sans-serif;
        font-weight: 300;
        color: #000;
        font-size: 16px;
        position: relative;
      }
      .secondaryLi:before{
        content: '';
        width: 3px;
        height: 3px;
        border:1px solid black;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: -10px;
      }
    }
  }
}
.application-btn {
  height: 40px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 10px;
  background-color: #12b423;
  transition: all .5s;
  color: #FEFEFE;
  border: 1px solid #12b423;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.btn_green {
  background-color: #12b423;
  color: #FEFEFE;
}

.application-btn:hover {
  color: #12b423;
  background-color: #FEFEFE;
  border: 1px solid #12b423;
}
.btn_toMain{
  margin: 0 auto;
  max-width: 70px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

@media (max-width: 850px) {
  .menuWrapper {
    .menu_inner {
      .rules_container {
        max-width: 95%;
        margin-top: 50px;
      }

      .policeTitle {
        font-size: 45px;
        font-family: 'Montserrat', Arial, sans-serif;
        color: #12b422;
        font-weight: 700;
        max-width: 95%;
        text-align: center;
        align-self: center;
        margin-top: 30px;
      }
    }
  }}
@media (max-width: 850px) {
  .menuWrapper {
    .menu_inner {
      .rules_container {
        max-width: 95%;
        margin-top: 25px;
      }

      .policeTitle {
        font-size: 35px;
        font-family: 'Montserrat', Arial, sans-serif;
        color: #12b422;
        font-weight: 700;
        max-width: 95%;
        text-align: center;
        align-self: center;
        margin-top: 20px;
      }
    }
  }}
</style>
